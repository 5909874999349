.tScroller {
    > div:nth-child(2) {
        //scroller
        background-color: none;
        &:hover,
        &:active {
            div > div {
                //scrollbar
                background-color: #ff8f98 !important;
                position: relative;
                left: 0 !important;
                width: 6px !important;
                border: 50%;
                &::after {
                    //thumb
                    content: '';
                    background-color: #ff8f98;
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 20px !important;
                    height: 20px !important;
                    border-radius: 50%;
                }
                > div {
                    //span role="alert"
                    opacity: 0;
                }
            }
        }

        > div {
            //scrollbar container
            background-color: #bfbfbf;
            position: relative;

            > div {
                //scrollbar
                position: relative;
                &:focus {
                    background-color: #ff8f98 !important;
                    position: relative;
                    left: 0 !important;
                    width: 6px !important;
                    border: 50%;
                    &::after {
                        //thumb
                        content: '';
                        background-color: #ff8f98;
                        position: absolute;
                        display: block;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 20px !important;
                        height: 20px !important;
                        border-radius: 50%;
                    }

                    > div {
                        //span role="alert"
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.onMove {
    > div:nth-child(2) {
        //scroller
        background-color: none;

        > div {
            //scrollbar container
            background-color: #bfbfbf;
            position: relative;

            > div {
                //scrollbar
                position: relative;

                background-color: #ff8f98 !important;
                position: relative;
                left: 0 !important;
                width: 6px !important;
                border: 50%;
                &::after {
                    //thumb
                    content: '';
                    background-color: #ff8f98;
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 20px !important;
                    height: 20px !important;
                    border-radius: 50%;
                }

                > div {
                    //span role="alert"
                    opacity: 0;
                }
            }
        }
    }
}

@__DEV__: true;