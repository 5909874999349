@import '../../style/CommonStyle.module.less';
.panel {
    @listTop: 763px;
    .panelBgImg{
        position:absolute;
        width: 100%;
        height: 705px;
        background-size: contain;
        background-position: right;
        // background-image: linear-gradient(133deg, #161616 31%, rgba(22, 22, 22, 0.24) 101%);
    }
    .showTitle{
        width: 1740px;
        height: 72px;
        line-height: 72px;
        position: absolute;
        font-family: "Pretendard";
        top: 180px;
        left: 90px;
        font-size: 60px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #343434;
    }
    .seasonTitle{
        width: 1720px;
        height: 36px;
        line-height: 36px;
        position: absolute;
        font-family: "Pretendard";
        top: 264px;
        left: 90px;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #6b6b6b;
    }
    .buttonLayer{
        position: absolute;
        top: 514px;
        left: 90px;
        width: 50%;
        display: flex;
        align-items: center;
    }
    .medialistlayer {
      top: @listTop;
      position: absolute;
      left: 0;
    }
    .tDropdown{
        margin: 0 !important;
        margin-left: 14px !important;
    }
    .informationBtn{
        margin-left: 14px;
    }
}
@__DEV__: true;