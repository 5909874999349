.panel {
	.headContainer{
		display: flex;
		width: 100%;
		height: 70px;
		.backBtn{
			margin: 68px 0 0 95px;
		}
		>div{
			margin: 90px auto;
			color: #010101;
			font-size: 30px;
  			font-weight: bold;
		}
	}
	// background-color:#fff;
	background: white;
	.title{
		display: inline-block;
		width: 100px;
		height: 100px;
	}
	.tabLayout{
		position: absolute;
		top: 160px;
		left: 90px;
		width: 500px;
		height: 830px;
		background-color: #f1f1f1;
		box-shadow: 20px 0 20px;
		// background-color: grey;
		// >div{
		// }
		.tab{
			&:first-child{
				margin-top: 60px;
			}
		}
	}
}

&.selected .button{
	background-color: #ff8f98;
}
@__DEV__: true;