@ROW_HEIGHT: 90px;
@ROW_TITLE_WIDTH: 175px;
@ROW_MARGIN: 20px;
@RIGHT_MARGIN: 24px;
@CHECKBOX_ITEM_PADDING: 14px;
@CHECKBOX_ITEM_MARGIN: 18px;
@POSITION_AREA_HEIGHT: 220px;

.infopopup{
	width: 861px !important;
    height: 791px;
	border-radius: 5px;
    border: 0.08333rem solid rgba(212, 214, 221, 0.753);
    background-color: #fafafa !important;
    margin-bottom: 153px !important;
	>div >div >div{
		width: 100% !important;
	} //임시
}
.titlelayer{
	margin-top: 5px;
	margin-bottom: 12px;
	.backBtn{
		display: inline-block;
	}
	.title{
		position: absolute;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		top: 8px;
		font-family: 'Pretendard';
		white-space: nowrap;
		font-size: 40px;
		line-height: 48px;
		font-weight: bold;
		letter-spacing: -1.2px;
		text-align: left;
		color: #010101;
	}
}

.row{
	position: relative;
    display: inline-flex;
    width: ~"calc(100% - @{ROW_MARGIN} * 2)";
    height: @ROW_HEIGHT;
    color: #ffffff;
    font-family: "Pretendard";
    margin-left: 20px;
    margin-right: 20px;
    border-width: 1px;
    border-color: #b2b2b2;
    border-bottom-style: solid;
	&.firstrow{
		margin-top: 10px;
	}
	.title{
		width: 175px;
		height: 30px;
		margin-top: ~"calc( ( @{ROW_HEIGHT} - 30px ) / 2)";
		font-family: 'Pretendard';
		font-weight: bold;
		font-size: 25px;
		margin-left: 20px;
		color: #010101;
	}
	.item{
		display: inline-block;
		font-family: Pretendard;
		font-weight: 500;
		width: auto;
		height: 30px;
		margin-top: ~"calc( ( @{ROW_HEIGHT} - 30px ) / 2)";
		font-size: 25px;
		color: #454545;
	}
	.onoffswitch{
		display: flex;
		align-items: center;
		height: 36px;
		margin-top: ~"calc( ( @{ROW_HEIGHT} - 36px ) / 2)";
		>div:last-child{
			font-family: Pretendard;
			letter-spacing: -0.75px;
			color: #454545;
			font-size: 25px;
			font-weight: 500;
			margin-left: 10px;
		}
		>div{
			margin: 0;
			padding: 0;
			>div:nth-child(3){
				margin-inline-start: 0;
			}
		}
	}
	.accessSwitch {
		position: relative;
		width: 200px;
		display: inline-flex;
		border-radius: 26px;
		margin: 0px;
		margin-left: -30px;
		font-size: 20px;
		font-family: 'Pretendard';
		font-weight: bold;
		&:focus{
		  > div:first-child{
			background-size: 100% @ROW_HEIGHT !important;
		  }
		}
	}
	.cameraArea {
		position: relative;
		display: inline-flex;
		width:  ~"calc(100% - @{ROW_TITLE_WIDTH} - @{RIGHT_MARGIN})";
		height: @ROW_HEIGHT;
		>div{
			margin-right: 50px;
			height: 44px;
			margin-top: ~"calc( ( @{ROW_HEIGHT} - 44px ) / 2)";
		}
		>div:last-child{
			margin-right: 0px;
		}
	}
	&.position{
		height: auto;
		.positionArea {
			height: @POSITION_AREA_HEIGHT;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.cmBox {
		position: relative;
		display: inline-block;
		width: 265px !important;
		height: 100%;
		margin: 0;
		margin-right: 18px;
		.checkBox{
			padding-right: @CHECKBOX_ITEM_PADDING;
			padding-top: 174px;
		}
		.bgBox{
			position: absolute;
			pointer-events: none;
			@BOXTOP: 46px;
			@BOXMARGIN: 10px;
			@BOXBORDER: 5px;
			top: 0;
			width:  ~"calc(100% - @{BOXBORDER} * 2)";
			height: 151px;
			border-radius: 2px;
			border: solid @BOXBORDER transparent;
			background-color: #e9e9e9;
			&.focused{
				border: solid @BOXBORDER #ff8f98;
			}
		}
		.miniBox{
			position: absolute;
			display: inline-block;
			width: 37%;
			height: 40%;
			border-radius: 2px;
			background-color: #ffffff
		}
		.subboxTopLeft {
			left: 3%;
			top: 5%;
		}
		.subboxBottomLeft {
			left: 2%;
			bottom: 2%;
		}
		.subboxTopRight {
			right: 3%;
			top: 5%;
		}
		.subboxBottomRight {
			right: 2%;
			bottom: 2%;
		}
	}
}
.buttonlayer{
	text-align: center;
	margin-top: 20px;
}
@__DEV__: true;