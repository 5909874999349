.termsPopup{
    box-shadow: none !important;
    width: 750px !important;
    background: white !important;
    color: black;
    margin-bottom: 50vh !important;
    transform: translateY(50%);
    padding: 10px 0;
    .termsItemsLayer{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .notice{
        // width: 500px;
        margin: 0 auto;
        text-align: center;
        font-family: "Pretendard";
        font-size: 1.04167rem;
        font-weight: 500;
        letter-spacing: -0.75px;
        color: #909090;
        margin-bottom: 20px;
    }
    .termsItemsRow{
        display: flex;
        justify-content: center;;
        margin: 10px 0;
        .checkbox{
            margin-top: 20px;
            margin-left: 30px;
        }
        .button{
            margin-bottom: 0px;
            margin-left: 10px;
        }
    }
    .title{
        font-weight: bold;
        font-size: 40px !important;
        margin: 30px auto;
        color: black;
        font-family: "Pretendard";
        text-align: center;
    }
    .subTitle{
        font-size: 32px;
        margin: 10px 0;
        text-align: center;
        color: black;
        font-family: "Pretendard";
    }
}

.checkPopup {
    padding: 0 20px;
    margin-bottom: 50vh !important;
    transform: translateY(50%);
}


.termsDetail{
    margin-left: 60px;
    text-align: left;
    color: #ccc;
    font-size: 25px;
    height: 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Pretendard";
}

.nomalBtn{
    margin : 10px 20px 0 20px;
}
@__DEV__: true;