@import '../../../../../../../style/CommonStyle.module.less';

.container{
    position: absolute;
    top: 230px;
    left: 50px;
    width: 91%;
    height: 71%;
    text-align:center;
    background: #f1f1f1;
    font-size: 25px;
    font-family: 'Pretendard';
    .title{
        padding: 100px 0 50px 0;
        letter-spacing: -0.75px;
        color: #1f1f1f;
        >span{
            font-weight: bold;
        }
    }
    .nameBox{
        color: #ff8f98;
    }
    .candyImgStyle{
        width: 50px;
        height: 50px;
        margin: 30px auto 65px;
        padding: 30px;
        background-size: cover;
        background-image: url('../../../../../../../../assets/icons/candy_band_focus.png');
    }
}
@__DEV__: true;