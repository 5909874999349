// Times.module.less
//
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/mixins.less";

.times {
	position: absolute;
	font-family: "Pretendard";
	width: 100%;
	font-size: 32px;
	font-weight: 500;
	line-height: 32px;
	white-space: nowrap;

	> * {
		display: inline-block;
	}

	.separator {
		padding: 0 1ex;
	}

	.enact-locale-rtl({
		direction: ltr;
	});
}

@__DEV__: true;