.subtitleWrap{
	position: absolute;
	bottom: 50px;
	width: 100%;
	white-space: pre-wrap;
	z-index: 1;
	.subtitle {
		color: rgb(255, 255, 255);
		text-shadow: -2.5px 0px 2px rgb(0, 0, 0), 0px 2.5px 2px rgb(0, 0, 0), 2.5px 0px 2px rgb(0, 0, 0), 0px -2.5px 2px rgb(0, 0, 0);
		font-family: "LG SmartFont";
		font-size: 58px;
		line-height: 1.4;
		background-color: transparent;
		text-align: center;
	}
}
@__DEV__: true;