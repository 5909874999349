// MediaTitle.module.less
//
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/mixins.less";
@import "~@enact/sandstone/styles/skin.less";

.titleFrame {
	--infoComponentsOffset: 0;

	@badges-present-transition: transform 500ms ease-in-out;

	position: relative;
	flex-grow: 1;
	opacity: 1;
	flex-direction: row;
	display: flex;



	&.hidden {
		transition: opacity 1000ms ease-in-out;
		opacity: 0;
	}

	// Title
	.title {
		width: auto;
		transition: @badges-present-transition;
		.font-kerning;
		flex-grow: 1;
		height: 60px;
		padding: 0;
		text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
		font-size: 48px;
		font-weight: bold;
		line-height: 60px;
		letter-spacing: -2.4px;
		text-align: left;
		color: rgb(255, 255, 255);

		&.infoVisible {
			transform: translateY(~"calc(var(--infoComponentsOffset) * -1)") translateZ(0);
		}

		.enact-locale-tallglyph({
			// The font-size .times is the same as .title in tallglyph locales so no
			// vertical adjustment is required to align their baselines
			bottom: 0;
		});
	}

	// Badges and title components
	.infoComponents {
		vertical-align: super;
		font-size: 54px;

		&.hidden {
			opacity: 0;
		}
		&.visible {
			transition: opacity 500ms ease-in-out;
		}

		> * {
			display: inline-block;
			margin: 0 12px;
		}

		.badgeTextIcon {
			font-family: @sand-font-family-bold;
			font-size: @sand-video-player-badge-text-size;
			text-align: center;
			white-space: nowrap;
			display: inline-block;
		}

		.fontLgIcons {
			font-family: "LG Icons";
		}
	}

	.applySkins({
		.titleFrame {
			color: @sand-video-player-title-color;

			.redIcon {
				background-color: @sand-video-player-redicon-bg-color;
				color: @sand-video-player-redicon-text-color;
			}
		}
	})
}

@__DEV__: true;