@import '../../../../../../style/CommonStyle.module.less';

.container{
    margin: 40px 85px;
    font-family: 'Pretendard';
    .title{
        font-size: 28px;
        font-weight: 600;
        letter-spacing: -0.9px;
        padding: 20px 0;
        color: #454545;
    }
    .soundContainer{
       margin-top: 30px;
    }
    .switch{
        width: 300px;
        font-size: 24px;
        margin:0;
        background: transparent !important;
        color: #bababa;
        &:before{
            content:"";
            position: absolute;
            left: 5px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #000;
        }
    }
}
@__DEV__: true;