@import '../../../style/CommonStyle.module.less';

.myGoal {
    .goalLayer {
        .historyData {
            margin-top: 25px;
            display: flex;
            > div {
                width: 165px;
                height: 106px;
                margin-right: 20px;
                border-radius: 5px;
                padding-left: 150px;
                &:first-child {
                    font-size: 20px;
                    letter-spacing: -0.4px;
                }
                > div {
                    font-size: 25px;
                    font-weight: bold;
                    letter-spacing: -0.5px;
                    height: 20px;
                    margin-top: 18px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .h2 {
            font-family: 'Pretendard';
            font-size: 30px;
            font-weight: bold;
            color: #010101;
            letter-spacing: -0.9px;
        }
    }
}

@__DEV__: true;