.popup {
    width: 1000px !important; 
    height: 863px;
    background: white !important;
    color: black;
    margin-bottom: 50vh !important;
    transform: translateY(50%);
    >div{
        >div{
            >div{
                width: 904px !important;
            }
        }
    }
}

.popupContent{
    width: 100%;
}

.header{
    position: relative;
    margin-top: 20px;
    display: flex;
    text-align: center;
}
.title{
    line-height: normal;
    font-size: 40px;
    font-family: Pretendard;
    font-weight: bold;
    letter-spacing: -1.2px;
    width: 800px;
    text-align: center;
    color: #010101;
    padding: 2px 10px;
}
.detail{
    height: 554px;
    background-color: white;
    margin-top: 41px;
    .message{
        font-family: 'Pretendard';
        font-size: 20px;
        margin: 10px 0;
        color: #737373;
    }
}

.bottomBtnLayer{
    margin-top: 40px;
    text-align: center;
}

@__DEV__: true;