@import '../../style/CommonStyle.module.less';
.tswitchitem{
    > div:first-child{  // switch focus
        background-color: transparent !important;
    }
    > div:nth-child(2) > div:nth-child(1) { // switch text
        width: 150px;
        text-align: left;
        color: #737373;
    }
    > div:nth-child(3){ //icon background
        >div{
            >div:nth-child(2){
                width: 83px !important;
                height: 41px !important;
                background-color: #f4f4f4 !important;
                box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                >div{
                    width: 32px;
                    font-size: 40px;
                    line-height: 36px;
                    top: 2px;
                    left: 0x;
                    color: @candy-spotlight-color !important;
                }
            }
        }
    }
    &.selected{
        >div:nth-child(3)>div>div:nth-child(2){
                background-color: @candy-spotlight-color !important;
                box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            >div{
                left: 50px;
                color: #fff !important;
            }
        }
    }
    &:focus{
        >div:nth-child(3)>div>div:nth-child(2){
            box-shadow: 0 0 15px 0 @candy-spotlight-color, 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        >div{
            color: transparent;
        }
    }
    }
    &.disabled{
        >div:nth-child(3)>div>div:nth-child(2){
            background-color: #f4f4f4 !important;
            box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        >div{
            color: #b2b2b2 !important;
            }
        }
        &:focus{
            >div:nth-child(3)>div>div:nth-child(2){
                box-shadow: 0 0 15px 0 @candy-spotlight-color, 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            >div{
                color: transparent;
            }
        }
        }
    }
}

@__DEV__: true;