.startpage{
    background-image: url(../../../../assets/startPageBG.png);
    .logo{
        position:absolute;
        height: 96px;
        width: 304px;
        left: 174px;
        top: 415px;
        background-size: contain;
        // todo
        background-image: url(../../../../assets/candy_logo_white.svg);
        transition: opacity 500ms ease-in-out;
        background-repeat: no-repeat;
    }
    .guideText{
        position:absolute;
        height: 96px;
        left: 174px;
        top: 521px;
        height: 36px;
        font-family: Pretendard;
        font-size: 30px;
        font-weight: 500;
        font-style: oblique;
        color: #ffffff;
    }
    .button{
        position:absolute;
        left: 174px;
        top: 620px;
    }
}
.title{
    position: relative;
    font-family: "LG SmartFont Bold";
    width: 1000px;
    height: 76px;
    padding-top: 60px;
    font-size: 80px;
    line-height: 84px;
    font-weight: bold;
    letter-spacing: 0px;
    vertical-align: middle;
    text-align: left;
    color: rgb(192, 192, 192);
    // will-change: transform;
}
.subtitle{
    position: relative;
    display: block;
    font-family: "LG SmartFont";
    width: 1000px;
    padding-bottom: 60px;
    font-size: 40px;
    line-height: 47px;
    word-break:keep-all;
    font-weight: lighter;
    overflow: hidden;
    margin-top: 46px;
    text-align: justify;
    text-justify: inter-word;
    color: rgb(192, 192, 192);
    white-space: pre-wrap;
}
.infoArea {
    margin-left: 100px;
    height: 400px;
    position: relative;
    transform: translateY(100%);
}

@__DEV__: true;