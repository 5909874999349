.datePicker {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    // // margin-top: 15px;
    // > div > :nth-child(2) > div {
    //     &:focus {
    //         color: aqua;
    //     }
    // }

    .textLayer {
        width: 350px;
        height: 96px;
        line-height: 96px;
        position: relative;
        overflow: hidden;
        .text {
            text-align: center;
            position: absolute;
            width: 100%;
            font-size: 25px;
            font-weight: bold;
            color: #000;
        }
        .text:first-child {
            transform: translateX(-100%);
        }
        .text:last-child {
            transform: translateX(100%);
        }
        .text.inc {
            transition: all 300ms ease;
            transform: translateX(-100%);
            &:first-child {
                transform: translateX(-200%);
            }
            &:last-child {
                transform: translateX(0%);
            }
        }
        .text.dec {
            transition: all 300ms ease;
            transform: translateX(100%);
            &:first-child {
                transform: translateX(0%);
            }
            &:last-child {
                transform: translateX(200%);
            }
        }
    }

    .button {
        // arrow both
        &:focus {
            > div:nth-child(2) > div {
                color: #ff8f98 !important;
            }
            transform: scale(1.4);
        }
        > div:nth-child(2) > div {
            color: #000 !important;
            font-size: 1rem;
            font-weight: 700;
        }

        > div:first-child {
            background: none !important;
        }
    }
}

@__DEV__: true;