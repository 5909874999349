@import '../../style/CommonStyle.module.less';

.tcheckbox{
	width: auto;
	.wrapper{
		width: auto;
		position: relative;
		display: flex;
		.checkboxBG{
			display: inline-block;
			width: 40px;
			height: 40px;
			border-radius: 5px;
			border: solid 2px transparent;
			background-color: #d9d9d9;
		}
		.selected{
			width: 40px;
			height: 40px;
			position: absolute;
			top: 2px;
			left: 2px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url("../../../assets/buttons/checkbox_selected.svg");
		}
		.text{
			margin-left: 11px;
			line-height: 44px;
			font-family: Pretendard;
			font-size: 25px;
			font-weight: 500;
			color: #454545;
		}
	}
	&:focus{
		.checkboxBG{
			background-color: @candy-spotlight-color;
			border: solid 2px #ffffff;
		}
	}
}
@__DEV__: true;