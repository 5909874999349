.app {
	// styles can be put here
	background-color: black;
	overflow: hidden;
	section{
		padding: 0 !important;
	}
}
[id=floatLayer]{
	>div>div>div[class*="scrim"]:first-child{
		background-color: rgba(0, 0, 0, 0.5);
	}
}
@__DEV__: true;