@import '../../style/CommonStyle.module.less';


.buttonContainer {
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    // padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 200ms ease-out;
    }
.pagination{
    position: relative;
    .button{
        width:15px;
        height:15px;
        // border: 9px solid #fff;
        background-color: #fff;
        border-radius: 50%;
    }
    &:focus .button{
        &::after{
            position: absolute;
            top: -9px;
            left: -9px;
            content:"";
            width:15px;
            height:15px;
            border-radius: 50%;
            border: 9px solid #fff;
            background-color: #ff8f98;
        }
    }
}
&.selected .button{
    background-color: #ff8f98;
    &::after{
        position: absolute;
        top: -9px;
        left: -9px;
        content:"";
        width:15px;
        height:15px;
        border-radius: 50%;
        border: 9px solid #ff8f98;
        background-color: #fff;
    }
}
@__DEV__: true;