@import '../../../../../../style/CommonStyle.module.less';

.container{
    margin: 40px 75px;
    font-family: 'Pretendard';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: -0.75px;
    color: #454545;
}
@__DEV__: true;