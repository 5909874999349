@import '../../../../../style/CommonStyle.module.less';
.termsContainer{
    position: absolute;
    top: 0;
    left: 520px;
    background-color: #f1f1f1;
    width: 1220px;
    height: 830px;
    color: #010101;
    .header{
        font-family: "Pretendard";
        font-size: 40px;
        margin: 75px 0 0 50px;
        color: #010101;
        font-weight: bold;
    }
    .tabLayout{
        font-family: "Pretendard";
        margin : 0 0 0 50px;
        display: flex;
        overflow: hidden;
        .tab{
            width: 177px;
            &.selected{
                width: 190px;
            }
            &:first-child{
                ::before{
                    content:"";
                    position: absolute;
                    height: 4px;
                    width: 1110px;
                    background: #000;
                    top: 225px;
                    left: 50px;
                    z-index: 1000;
                    pointer-events: none;
                }
            }
        }
    }
}
@__DEV__: true;