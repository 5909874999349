.header{
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 500;
  color: #010101;
  margin-bottom: 20px;
}
.productList{
  width: 100%;
  height: 321px;
  position:relative;
  >div{
    overflow: hidden !important;
  }
}
.topBottomScrollArea{
  position:fixed;
  left: 0;
  width: 100%;
  height: 75px;
  z-index: 21;
  transition: all 300ms ease;
  &.touchGuide{
      opacity: 0.3;
      background-color: darkorange;
  }
}
.topScrollArea{
  top: 370px;
}
.bottomScrollArea{
  position: fixed;
  bottom: 0;
  background-image: linear-gradient(to top, #fafafa, rgba(250, 250, 250, 0));
}
.productItem {
  width: 100%;
  height: 200px;
  border-color: #010101;
  border-width: 1px;
  border-top-style: solid;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  .image{
    width: 250px;
    height: 150px;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    transition: opacity 500ms ease-in-out;
    background-size: contain;
    background-position: center;
  }
  .textLayer{
    width: 440px;
    margin-left: 30px;
    margin-right: 30px;
    font-family: Pretendard;
    font-weight: 500;
    color: #454545;
    .title{
      font-size: 25px;
    }
    .subtitle{
      font-size: 30px;
      color: #010101;
    }
  }
  .qrcode{
    width: 120;
    height: 120px;
    background-color: white;
    padding: 5px;
  }
}
.noneResult {
  position: relative;
  width: 100%;
  margin-top: 80px;
  text-align: center;
  font-size: 30px;
  line-height: 1.29;
  font-family: 'Pretendard';
  color: #010101;
}
@__DEV__: true;