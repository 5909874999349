/* MainView */
@import '~@enact/sandstone/styles/mixins.less';

.panelMain {
	visibility: visible;
}

.hide {
	transform: translateX(3840px);
	visibility: hidden;

	:global(.enact-locale-right-to-left) & {
		transform: translateX(-3840px);
	}
}
.repeater {
	position:absolute;
}
@__DEV__: true;