@import '../../style/CommonStyle.module.less';

.bodyarea{
    .mainpanel-bodyarea();
}
@bodyHeight: 1080px - @toplayerHeight;
@scrollerTopPadding: 20px;
.scroller{
    // left: 32px;
    // width: 1920px- 32px;
    left:0px;
    width: 1920px;
    padding-top: @scrollerTopPadding;
    >div{
        overflow: hidden !important;
    }
    &.medium{
        height: @bodyHeight - @bannerMedium;
    }
    &.small{
        height: @bodyHeight - @bannerSmall;
    }
    .dummy{
        height: 10px;
        width:100%;
    }
}
.topBottomScrollArea{
    position:absolute;
    width: 100%;
    height: 100px;
    z-index: 21;
    &.touchGuide{
        opacity: 0.3;
        background-color: darkorange;
    }
}
.topScrollArea{
    // transition: all 300ms ease;
    top: @bannerSmall;
    height: 73px;
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
    &.medium{
        top: @bannerMedium;
    }
}
.bottomScrollArea{
    bottom:0px;
    height: 91px;
    background-image: linear-gradient(to top, #fafafa, rgba(250, 250, 250, 0));
}
@__DEV__: true;