.detail {
    position: absolute;
    top: 280px;
    left:55px;
    width: 1108px;
    height: 514px;
    font-family: 'Pretendard';
    font-size: 22px;
    .terms{
        position: absolute;
        left: 20px;
        width: 1050px;
        height: 514px;
    }
    >div{
        color: #737373;
    }

}

@__DEV__: true;