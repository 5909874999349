.tpanel{
    text-align: center;
}
.title{
    display: inline-block;
    position: relative;
    font-family: "Pretendard";
    height: 76px;
    padding: 60px 0 20px 0;
    font-size: 45px;
    line-height: 84px;
    font-weight: bold;
    letter-spacing:  -1.35px;
    vertical-align: middle;
    text-align: center;
    color: #010101;
}
.detail{
    font-family: "Pretendard";
    font-size: 30px;
    letter-spacing: -0.9px;
    text-align: center;
    color: #454545;
    padding: 65px 0;
}
.desc {
    font-family: "Pretendard";
    font-size: 25px;
    color: #909090;
}
.infoArea {
    margin: 100px;
    height: auto;
    position: relative;
}
.buttonWrap {
    margin: 60px auto;
    text-align: center;
    .button{
        width: 300px;
        height: 70px;
        margin-left: 10px;
        margin-right: 10px;
    }
}
.inputBox{
    font-family: "Pretendard";
    margin: 30px auto;
    padding: 0 68px;
    >div{
        &:focus{
            background: white !important;
        }
    }
    .inputField{
        margin-right: 20px;
        margin-left: 5px;
        padding:0 !important;
        font-size: 30px;
        color: #010101;
        letter-spacing: -0.9px;
        >div{
            display:none;
        }
        >input{
            width: 450px !important;
            height: 80px !important;
            border-bottom: 4px solid #b2b2b2 !important;
            &:focus{
                border-bottom: 4px solid #010101 !important;
            }
        }
    }
}




@__DEV__: true;