@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/mixins.less";
@import "~@enact/sandstone/styles/skin.less";

.controlsFrame {
	position: relative;
	display: block;

	&.hidden {
		will-change: opacity;
		opacity: 0;
	}

	.mediaControls {
		text-align: center;
		direction: ltr;
		white-space: nowrap;

		> *:first-child {
			margin-inline-start: 0;
		}
	}

	.actionGuide {
		padding-top: 39px;
		transition: opacity @sand-mediaplayer-controls-actionguide-time linear;
		&.hidden {
			opacity: 0;
		}
	}

	.moreComponents {
		position: absolute;
		left: 0;
		right: 0;
		height: 0px;
		opacity: 0;

		.moreButtonsComponents {
			> * {
				margin: 0;
				margin-inline-start: 114px;

				&:first-child {
					margin-inline-start: 0;
				}
			}
		}

		> :first-child {
			margin-top: 21px;
		}
	}

	.button {
		height: 90px;
		min-width: 90px;
		margin: 0;
		margin-inline-start: 45px;

		.client {
			padding: 0 9px;
		}

		.bg {
			border-radius: 48px;
		}
	}
}

@__DEV__: true;