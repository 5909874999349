@import '../../../style/CommonStyle.module.less';

.totalData {
    float: left;
    position: fixed;
    text-align: center;
    border-radius: 5px 0 0 5px;
    font-size: 20px;
    z-index: 10;
    .date {
        width: 315px;
        height: 94px;
        line-height: 94px;
        margin-right: 2px;
        background-color: #010101;
        border-radius: 5px 0 0 5px;
        font-size: 25px;
        font-weight: bold;
        letter-spacing: -0.75px;
    }
    .totalInner {
        position: absolute;
        top: 0;
        left: 315px;
        width: 670px;
        height: 94px;
        display: flex;
        font-size: 25px;
        .dataDetail {
            position: relative;
            width: calc(670px / 3);
            height: 94px;
            color: #010101;
            background-color: #f0f0f0;
            &:last-child {
                border-radius: 0 5px 5px 0;
            }
            &:not(:last-child)::before {
                content: '';
                position: absolute;
                top: 15px;
                right: 0;
                width: 1px;
                height: 64px;
                background: #bfbfbf;
            }
        }
    }
}

.dataCotent {
    padding-top: 18px;
    font-size: 20px;
    letter-spacing: -0.4px;
    color: #454545;
}

.totalDataDetail {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.6px;
    color: #010101;
}

@__DEV__: true;