@import '../../style/CommonStyle.module.less';

.tButton{
    background-color: rgba(233, 233, 233, 0.29);
    display: inline-block;
    position: relative;
    width: 185px;
    height: 49px;
    color: rgb(255, 255, 255);
    border-radius: 0px 43.2px 43.2px 0px;
    border: 2px solid rgb(250, 250, 250);
    padding-top: 18.72px;
    padding-left: 16px;
    text-align: center;
    font-family: "LG SmartFont SemiBold";
    text-shadow: 2.4px 2.4px 16.8px rgba(0,0,0,0.79);
    box-shadow: 1.68px 1.68px 12px rgba(0,0,0,0.28);
    margin-top: 11px;
    .skipicon{
		display: block;
		position: absolute;
        top: 56%;
        transform: translateY(-50%);
		left: 10px;
		width: 40px;
		height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../../assets/list/icon/icon_player_left.png");
	}
    &:focus{
        background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6));
        width: 220px;
		height:60px;
        top: 0px;
        left: 0px;
        color: rgb(255, 255, 255);
        border-radius: 0px 48px 48px 0px;
        border: 2px solid rgb(250, 250, 250);
        font-size: 35px;
        padding-top: 30px;
        padding-left: 10px;
        text-align: center;
        text-shadow: 2.4px 2.4px 16.8px rgba(0,0,0,0.79);
        box-shadow: 1.68px 1.68px 12px rgba(0,0,0,0.28);
        margin-top: 0px;
    }
}

// 오른쪽 skip버튼
.right {
    padding-left:0px;
    padding-right: 16px;
    border-radius: 1.8rem 0rem 0rem 1.8rem;
    &:focus{
        left: initial;
		right: 0px;
		border-radius: 2rem 0rem 0rem 2rem;
    }
    .skipicon{
        left: initial;
        right: 10px;
        background-image: url("../../../assets/list/icon/icon_player_right.png");
    }
}
.disabled{
    background-color: initial;
    border: initial;
    box-shadow: initial;
}
@__DEV__: true;