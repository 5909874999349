@import '../../../../style/CommonStyle.module.less';

@BUTTON_LAYER_WIDTH: 260px;
.infopopup{
	width: 1500px !important;
	height: 850px !important;
	margin-bottom: 90px !important;
	padding-top: 35px;
	padding-bottom: 20px;
	border-radius: 22px;
	border: 2px solid rgba(168, 169, 172, 0.753);
	background: linear-gradient(to bottom, rgba(243, 243, 243, 0.66), rgba(212, 212, 212, 0.65), rgba(177, 176, 185, 0.76), rgba(166, 165, 173, 0.6), rgba(153, 153, 160, 0.6)) !important;
	background-size: cover !important;
	opacity: 1;
	z-index: 101;
	>div{
		height: 100%;
		>div{
			height: 100%;
			>div{
				height: 100% !important;
				width: 100% !important;
			}
		}
	}
}

.titlelayer{
	width: 90%;
	height: 10%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 3px;
	padding-left: 15px;
	padding-right: 15px;
	border: 2px solid rgba(168, 169, 172, 0.753);
	background-color:rgba(221, 221, 221, 0.85);
	position: relative;
	display: flex;
	border-radius: 22px;
	color: rgb(8, 8, 8);
	font-family: "LG SmartFont";
	.title{
		width: 75% !important;
		position: relative;
		display: inline-block;
		font-size: 34px;
		white-space: pre-wrap;
		line-height: 1.4;
		width: 100%;
		height: 100%;
		padding: 15px;
	}
	.date {
		width:25%;
		font-size: 28px;
		padding-top: 24px;
		padding-right: 20px;
		text-align: end;
	}
}
.scrollerlayer{
	width: 90%;
	height: 77%;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	border: 2px solid rgba(168, 169, 172, 0.753);
	background-color:rgba(211, 211, 211, 0.85);
	position: relative;
	border-radius: 22px;
	.scroller{
		width: 100%;
		height: 100%;
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 28px;
		font-family: "LG SmartFont";
		white-space: pre-wrap;
		line-height: 1.4;
		padding: 20px;
		color: rgb(29, 29, 29);
	}
}

.buttonlayer{
	.leftButton{
		position: absolute;
		top: 340px;
		left: -25px;
		padding: 10px 0px;
		color: white;
		border-radius: 15px;
		&:focus{
			background: linear-gradient(275deg, rgba(90, 111, 146, 0.0), rgba(90, 111, 146, 0.57), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
		}
	}
	.rightButton{
		position: absolute;
		top: 340px;
		right: -25px;
		padding: 10px 0px;
		color: white;
		border-radius: 15px;
		&:focus{
			background: linear-gradient(85deg, rgba(90, 111, 146, 0.0), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
		}
	}
	.checkButton{
		position: relative;
		margin-top: 25px;
		text-align: center;
	}
}

@__DEV__: true;