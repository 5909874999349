// VideoPlayer.module.less
//
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/mixins.less";
@import "~@enact/sandstone/styles/skin.less";

.videoPlayer {
	// Set by counting the IconButtons inside the side components.
	--liftDistance: 0px;

	overflow: hidden;

	.video {
		height: 100%;
		width: 100%;
	}

	.preloadVideo {
		display: none;
	}

	.overlay {
		position: absolute;
		.position(0);

		&::after {
			content: "";
			position: absolute;
			.position(auto, 0, 0, 0);
			height: 80%;
			transform-origin: bottom;
			// Fancier gradient for future reference. Keeping linear-gradient as specified from Enyo.
			// background-image: radial-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%);
			// background-size: 170% 200%;
			// background-position: bottom center;
		}
	}
	@keyframes spin {
		0% {
		  transform: rotate(0.25turn);
		}
		33% {
		  transform: rotate(0.5turn);
		}
		80% {
		  transform: rotate(0.95turn);
		}
		85% {
		  transform: rotate(1turn);
		}
		100% {
		  transform: rotate(1.25turn);
		}
	  }
	.spinner {
		background-image: url("../../../assets/icon_loading.png");
		width: 154px;
		height: 154px;
		position: relative;
		background-size: cover;
		overflow: hidden;
		margin-left: ~"calc(50vw - 77px)";
		margin-top: ~"calc(50vh - 77px)";
		animation: none 1.25s linear infinite;
		animation-name: spin;
		// animation-play-state: paused;
	}
	.controlFeedbackBtnLayer{
		position: absolute;
		z-index: 50;
		top: 506px;
		left: 0;
		padding: 0;
		width: 100%;
		height: 94px;
		display: flex;
		justify-content: space-between;
		&.lift {
			transform: translateY(~"calc(var(--liftDistance) * -1)");
			transition: transform 0.3s linear;
		}
	}
	.fullscreen {
		.miniFeedback {
			position: absolute;
			z-index: 50;
			bottom: 0;
			left: 0;
			padding: 0;
			width: 100%;
			pointer-events: none;
			.inforLayer{
				position: fixed;
				left: 25px;
				top: 25px;
				transform: scale(0.75);
				transform-origin: left top;
				width: 418px;
				height: auto;
				padding-bottom: 50px;
				border: solid 2px #ffffff;
				background-color: rgba(255, 255, 255, 0.7);
				border-radius: 5px;
				.graph{
					display: inline-block;
					width: 247px;
					height: 230px;
					margin-top: 44px;
					margin-left: 86px;
					margin-bottom: 16px;
				}
				&.right{
					right: 25px;
					left: initial;
					transform-origin: right top;
				}
			}
			.timeLeft{
				float: right;
				margin-bottom: 43px;
				margin-right: 94px;
				font-family: Pretendard;
				font-size: 92px;
				line-height: 92px;
				font-weight: 500;
				color: #fafafa;
			}
		}
		.exitBtnLayer{
			position: absolute;
			z-index: 50;
			top: 0px;
		}
		.playPauseButton{
			position: absolute;
			left: ~"calc(50vw - 75px)";
			top: ~"calc(50vh - 75px)";
			margin: 0px;
		}
		.bottom {
			position: absolute;
			height: auto;
			z-index: 100;  // Value assigned as part of the VideoPlayer API so layers may be inserted in-between
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			// left: @sand-video-player-padding-side;
			// right: @sand-video-player-padding-side;

			&.lift {
				transform: translateY(~"calc(var(--liftDistance) * -1)");
				transition: transform 0.3s linear;
			}

			&.hidden {
				pointer-events: none;
				top: 100%;
				.sliderContainer {
					position: absolute;
					left: 0;
					right: 0;
				}
			}

			.infoFrame {
				display: flex;
				margin-left: 64px;
			}
			.burningInfoContainer{
				height: 167px;
				margin-left: 90px;
				margin-right: 90px;
				border-style: solid;
				border-color: transparent transparent black transparent;
				border-width: 1px;
				.cameraButton{
					width: 80px;
					height: 80px;
					margin-top: 50px;
					float: right;
					background-image: url('../../../assets/icons/camera_1.png');
					background-size: cover;
					&:focus{
						background-image: url('../../../assets/icons/camera_2.png');
					}
				}
			}
			.titleContainer{
				position:relative;
				margin-left: 90px;
				margin-right: 90px;
				height: 100px;
				display: flex;
				justify-content: space-between;
				.title{
					font-family: "Pretendard";
					font-size: 40px;
					font-weight: bold;
					color: #ffffff;
					line-height: 100px;
					width: 80%;
				}
			}
			.sliderContainer {
				// display: flex;
				position:relative;
				align-items: center;
				margin-left: 90px;
				margin-right: 90px;
				height: 100px;
				margin-bottom: 20px;
				display: flex;
				> *:first-child {
					text-align: right;
				}

				.enact-locale-rtl({
					direction: ltr;
				});
			}
			.candyMediaControls{
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.controlsHandleAbove {
		pointer-events: none;
		position: absolute;
		.position(0, 0, auto, 0);
	}

	// Skin colors
	.applySkins({
		.fullscreen {
			.bottom {
				background-color: @sand-video-player-bottom-bg-color;

				.infoFrame {
					text-shadow: @sand-video-player-title-text-shadow;
				}
			}
		}

		.overlay {
			z-index: 2;
			&.scrim::after {
				background: @sand-video-player-scrim-gradient-color
			}
		}
	});
	&.cooldown {
		.scrim::after {
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
		}
		.bottom {
			background-color: transparent;
		}
		.sliderContainer{
			border-radius: 50px;
			background-color: rgba(0, 0, 0, 0.5);
			margin-bottom: 45px !important;
			>div:first-child,
			>div:nth-child(2){  //time
				width: calc(100% - 92px);
				margin-left: 46px;
    			margin-right: 46px;
			}
			>div:nth-child(3){  //slider
				margin-left: 150px;
				margin-right: 150px;
			}
		}
		.titleContainer{
			.title{
				width: 100% !important;
			}
		}
	}
}
@__DEV__: true;