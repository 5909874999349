@import '../../style/CommonStyle.module.less';
@itemHeight: 260px; // 284px

.listContainer {
    width: ~"calc(100% - @{list_default_left})";
    display: inline-block;
    position: relative;
    transition: transform 200ms ease-out;
    padding-left: @list_default_left;
    height: 293px;
    .listTitlelayer{
        height: 50px;
        margin-top: 0px;
        margin-bottom: 0px;
        left: 0px;
        display: inline-block;
        position: relative;
        .titleBtn{
            margin-left: -10px;
            margin-bottom: 0;
            height: 40px;
            line-height: 40px;
            font-size: 28px;
            width: 260px;
            padding-top: 10px;
            > div {
                padding-left: 10px;
            }
            &::after{
                top: 23px;
                width: 12px;
                height: 12px;
            }
            &:focus{
                >div{
                    border: initial;
                    &::after{
                        top: 22px;
                        right: 28px;
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
        .listTitle {
            font-family: Pretendard;
            color: #010101;
            font-weight: 500;
            display: inline-block;
            position: relative;
            width: 1700px;
            height: 30px;
            line-height: 30px;
            margin-left: 0px;
            font-size: 28px;
            padding-top: 20px;
        }
    }
    .listMain{
        width: 100%;
        height: @itemHeight;
        white-space: nowrap;
    }
    &.vertical{
        width: auto;
        height: auto;
        padding-left: 0px;
        overflow: hidden;
        min-width: 1800px;
        .listMain{
            white-space: initial;
            height: auto;
        }
    }
    .leftRightScrollArea{
        position:absolute;
        background-color: blue;
        top: 0px;
        opacity: 0.3;
        width: 100px;
        height: 100%;
        z-index: 21;
        &.hide{
            opacity: 0;
        }
    }
    .leftScrollArea{
        left:0px;
    }
    .rightScrollArea{
        right:0px;
    }
}
.verticalmedialistlayer{
    width: auto;
    position:absolute;
    height: 408px;
    padding-left: @list_default_left;
    .dummy{
        height: 10px;
        width: 100%;
    }
}
.topBottomScrollArea{
    position:absolute;
    width: 100%;
    height: 100px;
    z-index: 21;
    &.touchGuide{
        opacity: 0.3;
        background-color: darkorange;
    }
}
.topScrollArea{
    transition: all 300ms ease;
    top: 440px;
    height: 73px;
}
.bottomScrollArea{
    bottom:0px;
    height: 91px;
    background-image: linear-gradient(to top, #fafafa, rgba(250, 250, 250, 0));
}
@__DEV__: true;