@import '@enact/sandstone/Button/Button.module.css';

.tpanel{
    text-align: center;
    font-family: "Pretendard";
}
.exitBtn{
    position: fixed;
	right: 80px;
	top: 80px;
}
.title{
    position: absolute;
    top: 210px;
    width: 100%;
    font-size: 45px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #010101;
}
.subtitle{
    position: absolute;
    top: 340px;
    width: 100%;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.9px;
    color: #454545;
}

.emailInputBox{
    position: absolute;
    width: 100%;
    top: 430px;
    margin: 0 auto;
    .emailInput{
        width: 416px;
        padding:0 !important;
        font-size: 30px;
        color: #010101;
        letter-spacing: -0.9px;
        >div{
            background: #fff !important;
            filter:none !important;
            border-bottom: 3px solid #010101;
            margin-bottom: -5px;
            border-radius: 0;
        }
    }
}

.inputField{
    position: absolute;
    display: flex;
    top: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
    .inputBox{
        font-size: 40px;
        font-weight:600;
        color: #b2b2b2;
        width: 80px;
        height: 80px;
        line-height: 80px;
        margin-left: 11px;
        margin-right: 11px;
        border-bottom: solid 2px #b2b2b2;
        &.inserted{
            color: #010101;
            border-bottom: solid 4px #010101;
        }
        &.checked{
            color:#010101;
            border-bottom: solid 4px #010101;
            opacity: 0.6;
        }
    }
}

.numberField{
    position: absolute;
    display: flex;
    top: 660px;
    margin-left: 50%;
    transform: translateX(-50%);
    .button{
        margin-left: 5px;
        margin-right: 5px;
        .icon{
            margin: 0;
            font-weight: 600;
            margin-bottom: 6px;
        }
    }
}
.buttonWrap {
    position: absolute;
    top: 780px;
    margin-left: 50%;
    transform: translateX(-50%);
    .button{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.bottomtitle{
    position: absolute;
    bottom: 170px;
    width: 100%;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -0.75px;
    color: #909090;
}




@__DEV__: true;