@import '~@enact/sandstone/styles/mixins.less';
@import '../../style/CommonStyle.module.less';

@itemWidth: 336px;
@itemHeight: 385px;

.noMediaText {
	height: 304px;
	text-align: center;
	line-height: 304px;
}

.mediaImage {
	position: relative;
    display: inline-block;
    width: ~"calc(@{itemWidth})";
    height: ~"calc(@{itemHeight})";
	margin-top: 10px;
	margin-bottom: 10px;
    margin-right: 13px;
	border-radius: 2px;
    // background-color: rgba(255, 255, 255, 0);
}
.hide {
	visibility: hidden;
	opacity: 0;
}
.imageItem {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding-top: 0px;

	.thumbContainer {
		margin: 0;
		width: 100%;
		height: 100%;
		// background-size: cover;
		// background-repeat: no-repeat;
		// background-image: url("../../../assets/list/frame.png");
		// transition: transform 200ms ease-in-out;
	}
	.image {
		position: absolute;
		z-index: 5;
		width: 100%;
		height: 100%;
		margin: 0;
		border-radius: 2px;
		border: 0px;
		background-size: 100%;
		background-position: center;
	}
	.captionContainer {
		position: absolute;
		z-index: 15;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
    	height: 100%;
		border-radius: 2px;
		.caption {
			position: absolute;
			width: 141px;
			height: 50px;
			left: 50%;
			top: initial;
			bottom: 0;
			transform: translate(-50%);
			z-index: 15;
			text-overflow: ellipsis;
			overflow: hidden;
			justify-content: center;
			white-space: nowrap;
			pointer-events: none;
			font-family: 'Pretendard';
			font-size: 25px;
			line-height: 50px;
			font-weight: 600;
			text-align: center;
			color: #737373;
		}
	}
}
.forceFocus {
	z-index: 20;
	opacity: 1;
	&.mediaImage {
		.image{
			transition: background-size 300ms ease-in-out !important;
			background-size: 150%;
			box-shadow: 0 0 0 10px @candy-spotlight-color inset;
		}
	}
	.captionContainer{
		.caption{
			color: @candy-spotlight-color;
			background-color: transparent;
			left: 50%;
			top: initial;
			bottom: 20px;
			transform: translate(-50%);
		}
	}
}
.selected {
	.captionContainer{
		background-color: initial;
		.caption{
			color: #fff;
			background-color: @candy-spotlight-color;
			border-radius: 2px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
&.effectFocus{
	.imageItemeffectFocus(@itemWidth, @itemHeight);
}
@__DEV__: true;