@IMAGE_SIZE: 168px;

.contentContainer{
    position: absolute;
    top: 0;
    left: 520px;
    background-color:#f1f1f1;
    width: 1220px;
    height: 830px;
    color: #010101;
    .header{
        font-family: "Pretendard";
        font-size: 40px;
        margin: 75px 0 0 50px;
        color: #010101;
        font-weight: bold;
        letter-spacing: -1.2px
    }
}

.nameLayer{
    position: absolute;
    top: 180px;
    left: 130px;
    width: 1050px;
    text-align: center;
    color: #1d1d1d;
    .profileImage{
        width: @IMAGE_SIZE;
        height: @IMAGE_SIZE;
        border-radius: 50%;
        margin-bottom: 10px;
    }
    .name{
        margin: 10px auto 40px auto;
        font-family: "Pretendard";
        font-weight: 600;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.9px;
    }
    .button{
        &:first-child{
            margin-bottom: 170px;
        }
    }
}

.logoutPopup{
    margin-bottom: 280px !important;
}

@__DEV__: true;