@toplayerHeight: 137px;
@bannerMedium: 483px;
@bannerSmall: 295px;
@dashboard-toplayer-height: 90px;
@button-focus-size: 1.02;
@button-press-size: 1.04;
@panel_header_height: 123px;
@list_default_left: 84px;
@listBorderSize: 10px;

@candy-spotlight-color: #ff8f98;
@candy-disabled-color: #b2b2b2;

.mainpanel-bodyarea() {
	position: absolute;
	top: @toplayerHeight;
	width: 100%;
	height: ~"calc(100% - @{toplayerHeight})";
 }
.dashboards-chart-bg() {
	position: relative;
	width: 866px;
	height: 380px;
	border-radius: 32px;
	box-shadow: inset 0px -10px 32px 0 rgba(255, 255, 255, 0.1), inset 0px 40px 100px 0 rgba(0, 0, 0, 0.15);
	border-style: solid ;
	border-width: 2.9px 0.5px 0.5px 0.5px;
	border-color: rgba(87, 87, 90, 0.356);
	border-image-slice: 1;
	background: linear-gradient(to bottom, rgba(111,108,116,0.31), rgba(81,85,90,0.28));
	background-origin: border-box;
	background-clip: content-box, border-box;
 }
 .imageItemeffectFocus(@itemWidth, @itemHeight){
	// 20220811 no effect
	// position: absolute;
	// width: ~"calc(@{itemWidth} + 26px)";
	// height: ~"calc(@{itemHeight} + 26px)";
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%,-50%);
	// border-radius: 36px;
	// background: conic-gradient(#ea6c81 0%, rgba(234, 108, 129, 0));
	// z-index: 1;
 }
.root{
	overflow: hidden;
}

@font-face {
	font-family: "Pretendard";
	src: url('../../assets/fonts/Pretendard-Regular.ttf');
	font-weight: normal;
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
@font-face {
	font-family: "Pretendard";
	src: url('../../assets/fonts/Pretendard-Bold.ttf');
	font-weight: bold;
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
@font-face {
	font-family: "Pretendard";
	src: url('../../assets/fonts/Pretendard-Medium.ttf');
	font-weight: 500;
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
@font-face {
	font-family: "Pretendard";
	src: url('../../assets/fonts/Pretendard-SemiBold.ttf');
	font-weight: 600;
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
// @font-face {
// 	font-family: "Avionic";
// 	src: url('../../assets/fonts/Fontspring-DEMO-avionicoblique-bold.otf');
// 	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
// }
// https://w3c.github.io/webvtt/#css-extensions
video::cue {
	color: rgb(255, 255, 255);
	text-shadow: -2.5px 0px 2px rgb(0, 0, 0), 0px 2.5px 2px rgb(0, 0, 0), 2.5px 0px 2px rgb(0, 0, 0), 0px -2.5px 2px rgb(0, 0, 0);
	font-family: "Pretendard";
	font-size: 58px;
	line-height: 1.2;
	background-color: transparent;
	visibility: hidden;
}