.ttoast{
	width: 50% !important;
	margin-bottom: 40px !important;
	background-color: rgb(143, 143, 143, 0.28) !important;
	>div:first-child{
		padding-top: 7px !important;
		padding-bottom: 7px !important;
	}
	>div>div>p:first-child{
		width: auto !important;
		font-size: 30px !important;
		color: white;
		font-family: "LG SmartFont" !important;
		line-height: 1.5 !important;
		text-align:center !important;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:pre-wrap;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}

@__DEV__: true;