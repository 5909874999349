@import '../../style/CommonStyle.module.less';

@BUTTON_LAYER_WIDTH: 260px;
.infopopup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 652px !important;
    height: auto !important;
    background: white !important;
    color: black;
    margin: 0 auto;
    box-shadow: none !important;
	.messageLayer{
		display: flex;
	    justify-content: center;
    	flex-direction: column;
		.message{
			max-height: 100%;
			height: auto;
			font-family: "Pretendard";
			font-size: 30px;
			font-weight: 600;
			letter-spacing: -0.9px;
			text-align: center;
			margin: 30px 0 0;
			>:first-child{
				font-size: 30px;
				// margin: 30px auto;
			}
			>:nth-child(2){
				width: 430px;
				height: 70px;
				background: #ebebeb;
				border-radius: 5px;
				line-height: 70px;
				margin: 20px auto;
			}
			>:last-child{
				font-size: 25px;
				color: #909090;
			}
		}
	}
    .bottomBtnLayer{
        text-align: center;
		padding-top: 40px;
		padding-bottom: 30px;
		>div{
			margin-left: 10px;
			margin-right: 10px;
		}
		.checkbox{
			margin: 20px 0 0 175px !important;
		}
    }
}

.logoutPopup{
	width: 652px;
	border-radius: 5px;
	.messageLayer{
		margin: 30px auto;
	}
    .bottomBtnLayer{
		margin: 0 auto;
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
@__DEV__: true;