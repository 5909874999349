@import '../../style/CommonStyle.module.less';

.burningItem{
	display: inline-block;
	margin-right: 40px;
	.burningIcon{
		width: 40px;
		height: 40px;
		margin-top: 39px;
		margin-bottom: 19px;
		background-repeat: no-repeat;
    	background-size: contain;
		margin-left: auto;
    	margin-right: auto;
		vertical-align: top;
		&.time{
			background-image: url('../../../assets/icons/group368.png');
		}
		&.kcal{
			background-image: url('../../../assets/icons/group369.png');
		}
		&.steps{
			background-image: url('../../../assets/icons/group370.png');
		}
		&.bpm{
			background-image: url('../../../assets/icons/group371.png');
		}
	}
	.info{
		height: 36px;
		flex-grow: 0;
		font-family: Pretendard;
		font-size: 30px;
		line-height: 36px;
		font-weight: bold;
		letter-spacing: -0.9px;
		text-align: center;
		&.time{
			min-width: 90px;
			color: #5fa7e8;
		}
		&.kcal{
			color: #ffd214;
		}
		&.steps{
			color: #ff645a;
		}
		&.bpm{
			color: #09c186;
		}
	}
}
.horizontal{
	&.burningItem{
		width: 400px;
		margin-left: 67px;
		margin-top: 26px;
		.burningIcon{
			margin: 0;
			display: inline-block;
			margin-right: 19px;
		}
		.info{
			min-width: 105px;
			display: inline-block;
			vertical-align: top;
			line-height: 40px;
			position: relative;
			top: 0;
			text-align: left;
		}
	}
}
@__DEV__: true;