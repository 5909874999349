@import '../../../style/CommonStyle.module.less';

.goalContainer {
    display: flex;
    color: #ffffff;
    .goalBox{
        position: relative;
        &:first-child{
            background: url("../../../../assets/icons/time.png") no-repeat 30px;
            background-size: 16%;
            background-color: #60a7e8;
            &:before{
                .line;
            }
        }
        &:nth-child(2){
            background: url("../../../../assets/icons/calorie.png") no-repeat 30px;
            background-size: 16%;
            background-color: #ffd214;
            &:before{
                .line;
            }
        }
        &:last-child{
            background: url("../../../../assets/icons/walk.png") no-repeat 30px;
            background-size: 16%;
            background-color: #ff645a;
            &:before{
                .line;
            }
        }
    }
}

.line{
    content: '';
    position: absolute;
    width: 1px;
    height: 106px;
    top:0;
    left: 110px;
    background-color: #ffffff;
}
@__DEV__: true;