@import '../../../style/CommonStyle.module.less';
.wrapper {
    position: relative;
    width: 925px;
    margin-bottom: 30px;
    .goalContainer {
        display: flex;
        width: 100%;
        color: #ffffff;
        .goalBox {
            display: flex;
            align-items: center;
            width: 307px;
            height: 60px;
            overflow: hidden;
            border-radius: 5px;
            background-color: #fbfbfb;
            .goalBoxItemLeft {
                flex: 1 0 auto;
                padding-left: 19px;
                font-size: 18px;
                font-weight: bold;
                text-align: left;
                color: #010101;
            }
            .goalBoxItemRight {
                flex: 1 0 auto;
                padding-right: 20px;
                font-size: 18px;
                text-align: right;
                color: #010101;
                .value {
                    display: inline-block;
                    font-weight: bold;
                }
                .text {
                    display: inline-block;
                    margin-left: 4px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            &:before {
                content: '';
                width: 1px;
                height: 60px;
                background-color: #e3e3e3;
            }
            &:first-child::before {
                display: none;
            }
        }
    }
}

@__DEV__: true;