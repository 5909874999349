@import '../../style/CommonStyle.module.less';
@focusScale: 1.1;
@bgImageFocus: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6));
@fillPerImage: linear-gradient(46deg, rgba(91, 113, 200, 40%), rgba(171, 130, 238, 40%));

.tButton {
    display: inline-block;
    transition: all 200ms ease;
    background-size: contain;
    &.disabled{
        opacity: 0.5;
    }
    .marquee{
        margin-left: 10px;
        margin-right: 10px;
    }
}

.normal {
    width: 296px;
    height: 66px;
    text-align: center;
    font-family: "Pretendard";
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px;
    background-color: #ffffff;
    color: @candy-spotlight-color;
    border: solid 2px @candy-spotlight-color;
    background-repeat: no-repeat;
    line-height: 66px;
    &.pink{
        color: white;
        border: solid 2px white;
        background-color: @candy-spotlight-color;
    }
    &.dark{
        color: white;
        border: solid 2px #9a9a9a;
        background-color: #9a9a9a;
    }
    &:focus{
        background-color: @candy-spotlight-color;
        color: #ffffff;
        border-color: white;
    }
    &.pink:focus{
        background-color: white;
        color: @candy-spotlight-color;
        border-color: @candy-spotlight-color;
    }
    &.tiny{
        width: 66px;
        &.disabled{
            border: 1px solid #b2b2b2;
            background-color: #f4f4f4;
            color:#b2b2b2;
        }
        &.disabled:focus{
            background-color: #b2b2b2;
            color: #9a9a9a;
        }
    }
    &.small{
        width: 212px;
    }
    &.huge{
        width: 443px;
        height: 121px;
        font-size: 35px;
        line-height: 121px;
    }
}

.fillAni{
    background: linear-gradient(to right, @candy-spotlight-color 50%, @candy-disabled-color 50%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0s ease-out;
    > div {
        color: #ffffff;
        //not good
        // mix-blend-mode: difference;
    }
}
.withArrow{
    background-image:url(../../../assets/buttons/btn_1.png);
    width: 524px;
    height: 124px;
    text-align: left;
    font-family: "LG SmartFont";
    color: rgb(255, 255, 255);
    font-size: 26px;
    background-repeat: no-repeat;
    >div{
        line-height: 113px;
        margin-left: 37px;
    }

    &:focus{
        background-image:url(../../../assets/buttons/btn_1_focus.png);
    }
}
.back{
    background-image:url(../../../assets/buttons/candy_back_normal.png);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    &:focus{
      background-image:url(../../../assets/buttons/back_focus.png);
    }
}
.hide{
    opacity: 0;
}

.btnCommon{
    width: 216px;
    height: 70px;
    text-align: center;
    font-family: "Pretendard";
    font-size: 25px;
    font-weight: 600;
    line-height: 70px;
    border-radius: 5px;
}

.tabBtnVertical{
    position: relative;
    background: transparent;
    box-shadow: none;
    width: 400px;
    height: 80px;
    margin-left: 50px;
    margin-bottom: 20px;
    font-family: "Pretendard";
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.9px;
    border-radius: 5px;
    line-height: 80px;
    color: #010101;
    >div{
        padding-left: 30px;
        padding-right: 60px;
        border: 2px solid transparent;
    }
    &::after{
        content:"";
        position:absolute;
        top: 30px;
        right: 30px;
        width: 15px;
        height: 15px;
        border-top: 3px solid #000;
        border-right: 3px solid #000;
        transform: rotate(45deg);
    }
    &.selected{
        &::after{
           opacity: 0;
        }
       >div{
            border-color: @candy-spotlight-color;
            border-radius: 5px;
            color: @candy-spotlight-color;
            // top: -4px;
            // left:-4px;
            &::after{
                content:"";
                position:absolute;
                top: 32px;
                right: 28px;
                width: 15px;
                height: 15px;
                border-top: 3px solid @candy-spotlight-color;
                border-right: 3px solid @candy-spotlight-color;
                transform: rotate(45deg);
        }
        }
    }
    &:focus{
        &::after{
            opacity: 0;
        }
        >div{
            background: @candy-spotlight-color;
            color: #fafafa;
            border-color: white;
            border-radius: 5px;
            box-sizing: border-box;
            &::after{
                content:"";
                position:absolute;
                top: 32px;
                right: 28px;
                width: 15px;
                height: 15px;
                border-top: 3px solid #fafafa;
                border-right: 3px solid #fafafa;
                transform: rotate(45deg);
            }
        }
    }
}


.tabBtnHorizon{
    width: 182px;
    height: 76px;
    line-height: 80px;
    font-family: "Pretendard";
    font-size: 25px;
    font-weight: 600;
    margin-top: 40px;
    border-radius: 5px 5px 0 0;
    background: #fff;
    text-align: center;
    margin-right: 2px;
    line-height: 80px;
    font-weight: 600;
    letter-spacing: -0.75px;;
    color : #737373;
    border: 2px solid transparent;
    &.selected{
        border: 2px solid #000;
        color: #000;
    }
    &:focus{
        background: #000;
        color: #fafafa;
        border: 2px solid #000;
        border-radius: 5px;
    }

}


.grayLineBtn{
    width: 332px;
    height: 72px;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.9px;
    text-align: left;
    color: #010101;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 40px;
    border: 4px solid #b2b2b2;
    line-height: 72px;
    background: #fff !important;
    >div{
        padding-left: 30px;
    }
    &:focus{
        border: 4px solid #ff8f98;
        border-radius: 5px;
        transition: none;
    }
}

.candyBandBtn{
    width: 982px;
    height: 80px;
    border: none;
    color: #050505;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #fff !important;
    background-image: url(../../../assets/icons/candy_band_nomal.png) !important;
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: 19px 50% !important;
    line-height: 80px;
    &.selected{
        background-color: #fff4f5;
        background-image: url(../../../assets/icons/candy_band_focus.png);
    }
    &:focus{
        width: 978px;
        height: 76px;
        border: 2px solid #ff8f98;
        background-color: #fff4f5 !important;
        background-image: url(../../../assets/icons/candy_band_focus.png) !important;
        >div>span{
            color: #ff8f98 !important;
        }
    }
}
@__DEV__: true;