@import '../../style/CommonStyle.module.less';
@listHeight: 385px;

.listContainer {
    width: ~"calc(100% - @{list_default_left})";
    display: inline-block;
    position: relative;
    transition: transform 200ms ease-out;
    padding-left: @list_default_left;
    padding-top: 30px;
    .listMain{
        width: 100%;
        height:  ~"calc(@{listHeight} + @{listBorderSize}*2)";
        white-space: nowrap;
    }
    .leftRightScrollArea{
        position:absolute;
        background-color: blue;
        top: 0px;
        opacity: 0.3;
        width: 100px;
        height: 100%;
        z-index: 21;
        &.hide{
            opacity: 0;
        }
    }
    .leftScrollArea{
        left:0px;
    }
    .rightScrollArea{
        right:0px;
    }
}
@__DEV__: true;