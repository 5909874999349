.panel{
    .exitBtn{
        position: absolute;
        top: 35px;
        left: 1769px;
    }
    .title{
        position: absolute;
        top: 142px;
        width: 100%;
        text-align: center;
        font-size: 74px;
        font-family: "LG SmartFont Bold";
    }
    .title_ko {
        top: 139px;
    }
    .subTitle{
        position: absolute;
        top: 273px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 34px;
        line-height: 40px;
        font-family: "LG SmartFont Light";
        .text_explain{
            margin-top: 10px;
            line-height: 1.2;
            font-size: 18px;
            font-family: "LG SmartFont Light";
        }
    }
    .subTitle_ko{
        top: 273px;
    }
    .termsItemsLayer{
        position: absolute;
        width: 50%;
        top: 433px;
        right: 710px;
        .termsItemsRow{
            width: 100%;
            height: 120px;
            float: right;
            >div{
                float: right;
            }
            .switch {
                width: 305px;
                height: 110px;
                font-size: 26px;
                font-family: "LG SmartFont";
            }
            .imoverage{
                width: 464px;
                font-family: "LG SmartFont";
                font-size: 24px;
                color: white;
                line-height: 111px;
                margin-left: 60px;
            }
        }
        &.withdraw{
            top: 472px;
        }
        &.ko{
            top: 390px;
            .termsItemsRow{
                height: 107px;
            }
            &.withdraw{
                top: 410px;
                .termsItemsRow{
                    height: 121px;
                }
            }
        }
        .checkbox{
            margin-top: 20px;
        }
    }

    .bottomBtnLayer {
        position: absolute;
        top: 912px;
        width: auto;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        .nomalBtn{
            position: relative;
            margin-left: 30px;
            margin-right: 30px;
        }
    }
}

.checkbox_allAgree{
    position: absolute;
    top: 810px;
    left: 800px;
}
.text_agree{
    position: absolute;
    top:820px;
    left:887px;
    font-size: 24px;
    font-family: "LG SmartFont";
}

.hide {
    opacity: 0;
    pointer-events: none;
}

@__DEV__: true;