.tradio{
    width: 300px;
    > div:first-child{
        //background-image: url("../../../assets/buttons/alarm_focus.png");
        background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;
        background-size: 123px 56px;
        border-radius: 25px;
    }
    >div>div:first-child {
        background-color:transparent !important;
        box-shadow :none !important;
    }
    > div:nth-child(2){ //radio button background
        border-radius: 25px;
        background-color: rgba(119, 119, 119, 0.541) !important;
        border-color: rgba(210, 193, 250, 0.507) !important;
        >div{
            color: rgba(119, 119, 119, 0.445) !important;
        }
    }
    &:focus{
        color: white !important;
    }
    &.selected{
        border-color: rgba(210, 193, 250, 0.507) !important;
        background-color:transparent !important;
        > div:nth-child(2)>div:nth-child(1){
            color: rgb(216, 71, 172) !important;
            background-color:transparent !important;
            box-shadow :none !important;
        }
        &:focus{
            > div:nth-child(2)>div:nth-child(1)>{
                color: rgb(216, 71, 172) !important;
                background-color:transparent !important;
                box-shadow :none !important;
            }
        }
    }
}
@__DEV__: true;