@import '../../style/CommonStyle.module.less';

@LEFT_MARGIN: 82px;
@CENTER_MARGIN: 24px;
@CHART_MARGIN: 43px;
.bodyarea {
    position: relative;
    font-family: 'Pretendard';
    .mainpanel-bodyarea();
    margin-top: 50px;
    .goalLayer{
        position: absolute;
        top: 10px;
        right: 90px;
    }
    .calendarLayer {
        position: absolute;
        bottom: 110px;
        left: 90px;
    }
    .historyLayer {
        position: absolute;
        right: 90px;
        bottom: 90px;
    }
    // .prePareImg{
    //     width: 100%;
    //     height: 100%;
    //     background-size: cover;
    //     margin: 0px;
    // }
}
// .dashboardBg{
// background-image: url(../../../assets/banner/candy_dashboard_bg.png);
// background-size: 620px;
// background-position: 100% 100%;
// background-repeat: no-repeat;
// background-color: #ffffff;
// ;
//   }

@__DEV__: true;