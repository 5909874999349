@import '../../style/CommonStyle.module.less';

.tpanelmain{
    width: 1920px;
    height: 1080px;
    background-size: cover;
    background-color: white;
}
.tHeader{
    position: relative;
    display: inline-flex;
    width: 100%;
    height: @panel_header_height;
    .backBtn {
      position: relative;
      margin-left: 73px;
      margin-top: 43px;
    }
    .title {
      position: absolute;
      font-size: 36px;
      margin-top: 63px;
      color: black;
      font-family: 'Pretendard';
      font-weight: bold;
      left: 50%;
      transform: translateX(-50%);
    }
}
@__DEV__: true;