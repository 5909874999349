.panel{
	.exitBtn{
		position: absolute;
		top: 35px;
		left: 1769px;
	}
	.textBox{
		background-color:rgba(255, 255, 255, 0.2);
		border-radius: 22px;
		position: absolute;
		width: 1578px;
		height: 688px;
		font-size: 24px;
		font-family: "LG SmartFont Light";
		word-break: break-all;
		top: 196px;
		left: 171px;
	}
	.scroller{
		width: 1558px;
		height: 688px;
		padding-left: 41px;
		padding-top: 34px;
		padding-bottom: 28px;
		padding-right: 31px;
		white-space: pre-wrap;
		font-size: 24px;
		font-family: "LG SmartFont Light";
		>div:nth-child(2) > div:nth-child(1) {
			background: url("../../../assets/termsConditions/bar_scroll.png");
			width: 16px;
			height: 640px;
			top: 12px;
			opacity: 0.5;
			> div:nth-child(1) {
				width: 16px;
				background-size: cover;
				&:focus{
					// background-image: url("../../../assets/termsConditions/scroll_thumbnail.png");
					left: 0;
				}
			}
		}
	}

	.bottomBtnLayer {
		position: absolute;
		top: 912px;
		width: auto;
		height: auto;
		left: 50%;
		transform: translateX(-50%);
		.nomalBtn{
			position: relative;
			margin-left: 30px;
			margin-right: 30px;
		}
	}
}

@__DEV__: true;