
@BIG_SIZE_WIDTH: 716px;
@BIG_SIZE_HEIGHT: 412px;
@MEDIUM_SIZE_WIDTH: 480px;
@MEDIUM_SIZE_HEIGHT: 276px;
@SMALL_SIZE_WIDTH: 358px;
@SMALL_SIZE_HEIGHT: 206px;
@CAMERA_INNER_MARGIN: 0px;
@CAMERA_BORDER_GAP: 0px;
@CAMERA_BORDER_RADIUS: 30px;

@LEFTRIGHT: 30px;
@TOPBOTTOM: 30px;

.covercam {
    position: absolute ;
    border-radius: @CAMERA_INNER_MARGIN + @CAMERA_BORDER_GAP;
    border: @CAMERA_BORDER_GAP solid rgba(220, 220, 220, 0.7);
    background-color: transparent;
    .camera {
        position: absolute !important;
        right: @CAMERA_INNER_MARGIN/2;
        top: @CAMERA_INNER_MARGIN/2;
        // border-radius: @CAMERA_INNER_MARGIN;
        transform: rotateY(180deg);
        -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    }
}
.calculateWidth (@width, @height) {
    width: @width;
    height: @height;
    .camera {
        width: @width - @CAMERA_INNER_MARGIN !important;
        height: @height - @CAMERA_INNER_MARGIN !important;
        border-radius: @CAMERA_BORDER_RADIUS;
    }
}
.big{
    .calculateWidth(@BIG_SIZE_WIDTH, @BIG_SIZE_HEIGHT );
}
.medium{
    .calculateWidth(@MEDIUM_SIZE_WIDTH, @MEDIUM_SIZE_HEIGHT );
}
.small{
    .calculateWidth(@SMALL_SIZE_WIDTH, @SMALL_SIZE_HEIGHT );
}
.lt{
    left: @LEFTRIGHT;
    top: @TOPBOTTOM;
}
.rt{
    right: @LEFTRIGHT;
    top: @TOPBOTTOM;
}
.lb{
    left: @LEFTRIGHT;
    bottom: @TOPBOTTOM;
}
.rb{
    right: @LEFTRIGHT;
    bottom: @TOPBOTTOM;
}

.hide{
    opacity: 0;
}

@__DEV__: true;