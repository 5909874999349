@import '../../style/CommonStyle.module.less';

.playpauseicon{
	width: 150px;
	height: 150px;
	border-radius: 150px;
	background-color: transparent;
	.play{
		width:100%;
		height: 100%;
		background-image: url('../../../assets/icons/playerPlayIcon.png');
		background-size: contain;
	}
	.pause{
		width:100%;
		height: 100%;
		background-image: url('../../../assets/icons/playerPauseIcon.png');
		background-size: contain;
	}
	&:focus{
		background-color: @candy-spotlight-color;
	}
	&.disabled:focus{
		background-color: rgba(255, 255, 255, 0.5);
	}
}

@__DEV__: true;