@import '../../../style/CommonStyle.module.less';

.scroller{
	width: 1800px;
	height: 800px;
	padding-left: 30px;
	padding-top: 30px;
	padding-right: 50px;
	padding-bottom: 30px;
	white-space: pre-wrap;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	font-size: 24px;
	font-family: "LG SmartFont Light";
	>div:nth-child(2) > div:nth-child(1) {
		width: 16px;
		height: 800px;
		left: -15px;
		top : -5px;
		opacity: 0.5;
		> div:nth-child(1) {
			width: 16px;
			background-size: cover;
			&:focus{
				left: 0;
			}
		}
	}
}

.list {
	width: 1700px;
	min-height: 380px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}
.itemContainer{
	height: 80px;
	.text{
		font-family: "LG SmartFont Light";
		font-size: 28px;
		color: white;
		line-height: 80px;
	}
	.date{
		width: 250px;
		float: left;
		margin-left: 45px;
	}
	.title{
		width: 1350px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	&.odd{
		background-color: rgba(255,255,255,0.07);
	}
	&:focus{
		background: linear-gradient(50deg, rgba(124, 135, 153, 0.84), rgba(127, 137, 155, 0.84), rgba(110, 91, 112, 0.82), rgba(115, 97, 117, 0.69)) !important;
	}
}
.empty_comment{
	position: absolute;
	text-align: center;
	width: 100%;
	top: 300px;
	font-size: 28px;
	line-height: 1.29;
	word-break: keep-all;
	font-family: "LG SmartFont Light";
}
.hide{
	opacity: 0;
}
@__DEV__: true;