@import '../../../style/CommonStyle.module.less';

.chart {
    position: relative;
    width: 985px;
    height: 660px;
    .chartInner {
        position: absolute;
        width: 985px;
        height: 545px;
        top: 90px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-bottom: 20px;
        // border: 5px solid #ea6c81;
        z-index: 1;
        border-bottom: 2px solid #e2e2e2;
    }
    .empty_comment {
        position: absolute;
        text-align: center;
        width: 985px;
        height: 530px;
        bottom: 0;
        font-size: 28px;
        word-break: keep-all;
        box-sizing: border-box;
        padding-top: 200px;
        z-index: 10;
        font-family: 'Pretendard';
        font-size: 25px;
        font-weight: bold;
        color: #010101;
        letter-spacing: -0.75px;
    }
    .mainTitle {
        text-align: center;
        line-height: 96px;
    }
}

.totalHistoryData {
    > :first-child {
        width: 277px;
        height: 96px;
    }
    > :last-child {
        width: 552px;
        height: 96px;
    }
}

// .hide{
//     opacity: 0;
// }

.button {
    position: absolute;
    color: #e5e5e5 !important;
    // background-color: rgba(143,143,143,0.28);
    // border: 2px solid rgba(114, 111, 111, 0.292);
    // border-radius: 20px;
    // box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
    // filter: initial !important;
    // background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6));
    &.up {
        top: 0;
        right: -10px;
    }
    &.down {
        bottom: 0;
        right: -10px;
    }
}
.buttonHidden {
    display: none;
}

@__DEV__: true;