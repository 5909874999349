@import '../../../style/CommonStyle.module.less';

.itemContainer {
    font-family: 'Pretendard';
    .text {
        font-family: 'Pretendard';
        font-size: 28px;
        color: white;
        line-height: 84px;
    }
    .detail {
        width: auto;
        height: 100%;
        .h2 {
            padding-top: 30px;
            font-size: 'Pretendard';
            font-size: 25px;
            font-weight: bold;
            color: #010101;
        }
        .detailWrap {
            display: flex;
            margin-top: 20px;
            height: auto;
            width: 925px;
            .imgStyle {
                width: 200px;
                height: 125px;
                margin: 0;
                border: 0px;
                border-radius: 2px;
                object-fit: cover;
                object-position: top;
            }
            .titleWrap {
                display: flex;
                flex-flow: column;
                position: relative;
                width: 700px;
                margin-left:30px;
                .title {
                    font-size: 'Pretendard';
                    font-size: 18px;
                    font-weight: bold;
                    color: #454545;
                }
                .episodeTitleContainer {
                    display: flex;
                    flex-flow: row wrap;
                    width: 100%;
                    .episodeTitle {
                        width: calc( 100% / 3);
                        margin-top: 10px;
                        font-weight: 500;
                        font-size: 18px;
                        color: #888888;
                    }
                }
            }
        }
    }
    .title {
        width: auto;
    }
    &.odd {
        height: 60px;
        background-color: #010101;
        .text {
            line-height: 60px;
        }
    }
}

@__DEV__: true;