@import '../../../../../style/CommonStyle.module.less';

.openSourceContainer{
    position: absolute;
    top: 0;
    left: 520px;
    background-color:#f1f1f1;
    width: 1220px;
    height: 830px;
    color: #010101;
    .header{
        font-family: "Pretendard";
        font-size: 40px;
        margin: 75px 0 0 50px;
        color: #010101;
        font-weight: bold;
        letter-spacing: -1.2px;
    }
    .textBox{
        font-family: "Pretendard";
        font-size: 22px;
        text-align: left;
        letter-spacing: -0.4px;
        width: 1110px;
        height: 650px;
        margin: 30px 0 0 50px;
        .textDetail{
            padding-right: 40px;
            color: #737373;
            height: 610px;
        }
    }
}

@__DEV__: true;