@import '../../style/CommonStyle.module.less';

.bodyarea{
    .mainpanel-bodyarea();
    .categorylist {
        margin-top: 50px;
        margin-bottom: 10px;
        transition: opacity 500ms ease-in-out;
    }
    .categorydetail{
        position: absolute;
        margin-top: 0px;
        top: 0;
        z-index: -1;
    }
    .hide{
        opacity: 0;
    }
    .medialistlayer{
        height: 390px;
        margin-top: 79px;
    }
}

@__DEV__: true;