@import '../../../style/CommonStyle.module.less';
.panel {
    .titleArea {
        position: relative;
        display: block;
        .titleBox {
            position: relative;
            display: inline-block;
            width: 420px;
            height: auto;
            left: 75px;
            top: 0px;
            .text {
              position: relative;
              color: black;
              top: 0px;
              font-size: 28px;
              text-align: start;
              margin-top: 13px;
              font-family: "LG SmartFont";
            }
        }
        .textArea {
            position: relative;
            display: inline-block;
            width: 1000px;
            height: auto;
            left: 60px;
            background-color: rgba(174, 0, 255, 0.0);
            .text {
              position: relative;
              color: black;
              font-size: 23px;
              text-align: start;
              margin-top: 13px;
              font-family: "LG SmartFont";
            }
        }
        .title {
            position: relative;
            width:100%;
            text-align: center;
            font-size: 36px;
            font-family:'LG SmartFont Bold';
        }
        .switchs {
          position: relative;
          display: inline-flex;
          width: 100%;
          .switchTitle {
            position: relative;
            height: 72px;
            width: 450px;
            margin-left: 75px;
            margin-top: 20px;
            font-size: 30px;
            font-family: "LG SmartFont Bold";
            color: deepskyblue;
          }
          .switch {
            position: relative;
            width: 200px;
            height: 72px;
            border-radius: 26px;
            margin: 0px;
            font-size: 20px;
            font-family: "LG SmartFont";
            &:focus{
              > div:first-child{
                background-size: 100% 72px !important;
              }
            }
          }
        }
    }
    .warning{
      font-family: "LG SmartFont Bold";
      color: red;
      background-color: black;
      font-size: 60px;
      line-height: 2.0;
      position: absolute;
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
}
@__DEV__: true;