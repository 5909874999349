@import '~@enact/sandstone/styles/mixins.less';
@import '../../style/CommonStyle.module.less';

@itemWidth: 336px;
@itemHeight: 233px;

.noMediaText {
	height: 304px;
	text-align: center;
	line-height: 304px;
}

.mediaImage {
	position: relative;
    display: inline-block;
    width: ~"calc(@{itemWidth})";
    height: ~"calc(@{itemHeight})";
	margin-top: 10px;
	margin-bottom: 10px;
    margin-right: 13px;
	border-radius: 2px;
    background-color: rgba(255, 255, 255, 0);
	// opacity: 0.7;
}
.hide {
	visibility: hidden;
	opacity: 0;
}
.imageItem {
	font-family: "Pretendard";
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding-top: 0px;
	// will-change: transform;

	.thumbContainer {
		margin: 0;
		width: 100%;
		height: 100%;
		// transition: transform 200ms ease-in-out;
		&.specialBtnContainer{
			background-image: initial;
			//background-color: yellow;
			width: 340px;
			height: 304px;
			//right: 50px;
			background-color: rgba(143, 143, 143, 0.28);
			border: 2px solid rgba(114, 111, 111, 0.292);
			border-radius: 25px;
			box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
			margin: 14px 10px 61px 10px;
		}
	}
	.image {
		position: absolute;
		z-index: 5;
		width: 100%;
		height: 100%;
		margin: 0;
		border-radius: 2px;
		border: 0px;
		background-position: top;
	}
	.captionContainer {
		position: absolute;
		z-index: 15;
	    width: 100%;
		height: 100%;
		border-radius: 2px;
	    bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-image: linear-gradient(to bottom, rgba(196, 196, 196, 0) 60%, rgba(0, 0, 0, 0.6) 87%);
		.caption{
			width: ~"calc(100% - 50px)";
			left: 25px;
			position: absolute;
			display: block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			z-index: 15;
			text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: #ededed;
		}
		.title {
			bottom: 30px;
			font-size: 25px;
			&.reverse{
				bottom: 40px;
				font-size: 16px;
				line-height: 32px;
				height: 32px;
			}
		}
		.subtitle{
			bottom: 4px;
			font-size: 16px;
			&.reverse{
				bottom: 6px;
				font-size: 24px;
				line-height: 48px;
				height: 48px;
			}
		}
	}
	.subtime{
		position: absolute;
		bottom: 60px;
		left: 25px;
		z-index: 15;
		text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		font-size: 16px;
		font-weight: 500;
		letter-spacing: -0.48px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		text-align: left;
		color: #fafafa;
	}
	.playIcon{
		background-image: url("../../../assets/list/icon/icon_play.png");
		width: 92px;
		height: 92px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-size: contain;
		z-index: 16;
		pointer-events: none;
	}
}
.forceFocus {
	z-index: 20;
	opacity: 1;
	&.mediaImage {
		.image{
			transition: background-size 300ms ease-in-out !important;
			background-size: 120%;
			// box-shadow: 0 0 0 10px @candy-spotlight-color inset;
			filter: brightness(67%);
		}
	}
	.captionContainer{
		box-shadow: 0 0 0 10px @candy-spotlight-color inset;
		background-image: linear-gradient(to bottom, rgba(255, 131, 141, 0) 60%, rgba(255, 131, 141, 0.83) 100%);
	}
}
&.effectFocus{
	.imageItemeffectFocus(@itemWidth, @itemHeight);
}
@__DEV__: true;