// Feedback.module.less
//
@import "~@enact/sandstone/styles/mixins.less";
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/skin.less";

.feedback {
	width: 100%;
	display: flex;
	justify-content: space-between;

	&.hidden {
		display: none;
	}

	.message {
		.sand-font({
			font-weight: @sand-video-feedback-message-font-weight;
		});
		font-weight: @sand-video-feedback-message-font-weight;
	}

	.icon {
		font-size: @sand-video-feedback-icon-font-size;
		height: inherit;  // Use the height and line-height from the parent element to always middle align the icon, regardless of the container's size
		line-height: inherit;
		vertical-align: bottom;

		.applySkins({
			color: inherit;
		});
	}
	.skipbuttonB {
		background-color: rgba(233, 233, 233, 0.29);
		display: block;
		position: absolute;
		width: 7.7rem;
		height: 2.05rem;
		top: 0px;
		left: 0px;
		color: rgb(255, 255, 255);
		border-radius: 0rem 1.8rem 1.8rem 0rem;
		border: 2px solid rgb(250, 250, 250);
		padding-top: 0.78rem;
		padding-left: 16px;
		text-align: center;
		text-shadow: 0.1rem 0.1rem 0.7rem rgba(0,0,0,0.79);
		box-shadow: 0.07rem 0.07rem 0.5rem rgba(0,0,0,0.28);
	}
	.skipiconB{
		display: block;
		position: absolute;
		top: 10px;
		left: 10px;
		width: 41px;
		height: 2.05rem;
		background-image: url("../../../assets/list/icon/icon_player_left.png");
	}

	// 오른쪽 skip버튼
	.skipbuttonP {
		background-color: rgba(233, 233, 233, 0.29);
		display: block;
		position: absolute;
		width: 7.7rem;
		height: 2.05rem;
		top: 0px;
		right: 0px;
		color: rgb(255, 255, 255);
		border-radius: 1.8rem 0rem 0rem 1.8rem;
		border: 2px solid rgb(250, 250, 250);
		padding-top: 0.78rem;
		padding-right: 16px;
		text-align: center;
		text-shadow: 0.1rem 0.1rem 0.7rem rgba(0,0,0,0.79);
		box-shadow: 0.07rem 0.07rem 0.5rem rgba(0,0,0,0.28);
	}
	.skipiconP{
		display: block;
		position: absolute;
		top: 10px;
		right: 10px;
		width: 41px;
		height: 2.05rem;
		background-image: url("../../../assets/list/icon/icon_player_right.png");
	}
}
// Focus왼쪽 skip버튼
.skipbuttonFocusB {
	background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6));
	display: inline-block;
	position: absolute;
	width: 220px;
	height:57px;
	top: 0px;
	left: 0px;
	color: rgb(255, 255, 255);
	border-radius: 0rem 2rem 2rem 0rem;
	border: 2px solid rgb(250, 250, 250);
	font-size: 35px;
	padding-top: 30px;
	padding-left: 10px;
	text-align: center;
	text-shadow: 0.1rem 0.1rem 0.7rem rgba(0,0,0,0.79);
	box-shadow: 0.07rem 0.07rem 0.5rem rgba(0,0,0,0.28);
}
.skipiconFocusB{
	display: block;
	position: absolute;
	transform: scale(110%, 110%);
	top: 18px;
	left: 10px;
	width: 40px;
	height: 60px;
	background-image: url("../../../assets/list/icon/icon_player_left.png");
}
// Focus오른쪽 skip버튼
.skipbuttonFocusP {
	background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6));
	display: inline-block;
	position: absolute;
	width: 220px;
	height:57px;
	top: 0px;
	right: 0px;
	color: rgb(255, 255, 255);
	border-radius: 2rem 0rem 0rem 2rem;
	border: 2px solid rgb(250, 250, 250);
	font-size: 35px;
	padding-top: 30px;
	padding-right: 10px;
	text-align: center;
	text-shadow: 0.1rem 0.1rem 0.7rem rgba(0,0,0,0.79);
	box-shadow: 0.07rem 0.07rem 0.5rem rgba(0,0,0,0.28);
}
.skipiconFocusP{
	display: block;
	position: absolute;
	transform: scale(110%, 110%);
	top: 18px;
	right: 10px;
	width: 40px;
	height: 60px;
	background-image: url("../../../assets/list/icon/icon_player_right.png");
}
@__DEV__: true;