@import '../../style/CommonStyle.module.less';

@resultItemWidth: 90px;
@resultItemLRMargin: 14px;
@resultItemSecondChildWidth: @resultItemWidth - @resultItemLRMargin;

.resentSearchsContainer{
	display: inline-block ;
	width: auto;
	height: inherit;
	.onFocus {
		background-color: @candy-spotlight-color !important;
	}
	.listItem{
		position: relative;
		display: flex ;
		width: auto !important;
		height: inherit;
		flex-grow: 0;
		margin: 0 5px 0 27px;
		border-radius: 20px;
		background-color: #fff4f5;
		.searchResult{
			position: relative;
			// display: inline-block;
			width: @resultItemWidth;
			height: 100%;
			margin: 0;
			padding: 0;
			margin-top: 10px;
			margin-left: @resultItemLRMargin;
			margin-right: @resultItemLRMargin;
			color: @candy-spotlight-color;
			font-size: 17px;
			font-family: 'Pretendard';

			> div{
				width: @resultItemSecondChildWidth !important;
				display: table-caption !important;
				height: 40px;
			}
			> div:first-child{
						background-color: transparent !important;
					}

			&:focus{
				color: white;
			}
		}
		.deletebutton {
			// display: inline-block;
			color: @candy-spotlight-color;
			width: 44px;
			height: 100%;
			margin: 0;
			padding: 0;
			margin-top: 10px;
			font-size: 17px;
			text-transform: uppercase;
			font-family: 'Pretendard';
			> div{
				display: table-caption !important;
				width: 44px !important;
				height: 40px;
			}
			> div:first-child{
				background-color: transparent !important;
			}
			&:focus{
				color: white;
			}

		}
		.onFocusItem {
			color: gray;
		}
	}
}

@__DEV__: true;