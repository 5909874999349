// FeedbackTooltip.module.less
//
@import "~@enact/sandstone/styles/mixins.less";
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/skin.less";

@sand-video-slider-tooltip-arrow-border-top:       12px;
@sand-video-slider-tooltip-position-bottom:        21px;
@sand-video-slider-tooltip-shift-position-bottom:  @sand-video-slider-tooltip-position-bottom + @sand-video-slider-tooltip-arrow-border-top;
@sand-video-slider-tooltip-thumbnail-border-width: 3px;
@sand-video-slider-tooltip-thumbnail-width: 213px;
@sand-video-slider-tooltip-thumbnail-height: 120px;
@sand-video-slider-tooltip-font-weight: normal;
@sand-video-slider-tooltip-line-height: 36px;
@sand-video-slider-tooltip-margin-bottom: 3px;
@sand-video-slider-tooltip-arrow-border-top: 12px;
@sand-video-slider-tooltip-arrow-border-left-right: 9px;

.feedbackTooltip {
	position: absolute;
	bottom: @sand-video-slider-tooltip-position-bottom;

	&.shift {
		bottom: @sand-video-slider-tooltip-shift-position-bottom;

		.content {
			position: absolute;
			.position(auto, 0, 0, 0);
		}
	}

	&.hidden {
		display: none;
	}

	.thumbnail,
	.content {
		pointer-events: none;
		will-change: transform;
	}

	.thumbnail {
		border-width: @sand-video-slider-tooltip-thumbnail-border-width;
		border-style: solid;
		border-radius: 0;
		overflow: hidden;
		transition: opacity 250ms;

		.image {
			margin: 0;
			width: @sand-video-slider-tooltip-thumbnail-width;
			height: @sand-video-slider-tooltip-thumbnail-height;
		}
	}

	&.thumbnailDeactivated {
		.thumbnail {
			opacity: @sand-video-slider-tooltip-deactivated-thumbnail-opacity;
		}
	}

	.content {
		.sand-font({
			font-size: 24px;
			font-weight: @sand-video-slider-tooltip-font-weight;
		});
		.sand-font-number();
		font-size:  24px;
		font-weight: @sand-video-slider-tooltip-font-weight;
		line-height: @sand-video-slider-tooltip-line-height;
		white-space: nowrap;
		text-align: center;
		margin-bottom: @sand-video-slider-tooltip-margin-bottom;
	}

	.alignmentContainer {
		position: relative;
		right: 50%;
	}

	.arrowContainer {
		position: absolute;
		left: 0;
		right: 0;

		&.hidden {
			display: none;
		}

		.arrow {
			margin: auto;
			width: 0;
			height: 0;
			border-top: @sand-video-slider-tooltip-arrow-border-top solid;
			border-right: @sand-video-slider-tooltip-arrow-border-left-right solid;
			border-left: @sand-video-slider-tooltip-arrow-border-left-right solid;
		}
	}

	.applySkins({
		.thumbnail {
			border-color: @sand-video-slider-tooltip-thumbnail-border-color;
		}

		.arrow {
			border-top-color: @sand-video-slider-tooltip-thumbnail-border-color;
			border-right-color: transparent;
			border-left-color: transparent;
		}

		.content {
			color: @sand-light-gray;
			text-shadow: @sand-mediaplayer-slider-tooltip-text-shadow;
		}
	});
}

@__DEV__: true;