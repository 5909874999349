@import '../../style/CommonStyle.module.less';
.panel{
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	.leftSide{
		// position: absolute;
		width: 740px;
		height: 100%;
		background-color: #ff8f98;
		.guide{
			width: 100%;
			margin-top: 93px;
			font-family: 'Pretendard';
			font-size: 35px;
			line-height: 40px;
			font-weight: 600;
			text-align: center;
			color: #fff;
		}
		.episodeTitle{
			margin-left: 71px;
			width: 598px;
			margin-top: 34px;
			font-family: 'Pretendard';
			font-size: 43px;
			line-height: 50px;
			font-weight: 500;
			color: #fff;
		}
		.image{
			width: 592px;
			height: 542px;
			margin-top: 55px;
			margin-left: 74px;
		}
	}
	.rightSide{
		flex-grow: 1;
		position: relative;
		height: 100%;
		>.title{
			position: absolute;
			top: 70px;
			width: 100%;
			font-size: 87px;
			line-height: 100px;
			font-family: Pretendard;
			font-weight: bold;
			font-style: oblique;
			text-align: center;
			color: #2f2f2f;
		}
		.message{
			position: absolute;
			font-family: Pretendard;
			top: 173px;
			width: 100%;
			line-height: 40px;
			text-align: center;
			font-size: 30px;
			font-weight: 500;
			color: #5a5a5a;
		}
		.dataLayer{
			position: absolute;
			top: 269px;
			width: 1010px;
  			height: 542px;
			box-shadow: 1.5px 2.6px 29px 0 rgba(0, 0, 0, 0.16);
			border: solid 2px #fff;
			background-color: #fff;
			left: 50%;
			transform: translateX(-50%);
			.values{
				position: absolute;
				width: 350px;
				height: 376px;
				top: 84px;
				left: 109px;
				.activityItem{
					font-family: 'Pretendard';
					font-size: 32px;
					font-weight: 500;
					color: black;
					// .title{

					// }
					.data{
						font-size: 60px;
						font-weight: 700;
						margin-top: 24px;
						margin-bottom: 44px;
						letter-spacing: -1.75px;
						&.time{
							color: #5fa7e8;
						}
						&.kcal{
							color: #ffd214;
						}
						&.steps{
							color: #ff645a;
						}
					}
				}
			}
			.graph{
				position: absolute;
				width: 360px;
				height: 358px;
				top: 92px;
				right: 131px;
			}
		}
	}
	.bottomSide{
		position: absolute;
		width: 100%;
		height: 200px;
		bottom: 79px;
		.cooldown{
			position: absolute;
			left: 149px;
			bottom: 0;
		}
		.normalBtns{
			position: absolute;
			left: 880px;
			bottom: 0;
			>div:first-child{
				margin-right: 14px;
			}
		}
		.autoGuide{
			position: absolute;
			left: 884px;
			bottom: 135px;
			color: black;
		}
	}
}
@__DEV__: true;