@import '../../style/CommonStyle.module.less';
.panel {

    .medialistlayer{
        width: auto;
        position: absolute;
        top: 274px;
        height: 806px;
    }
    .tabLayout{
        font-family: "Pretendard";
        margin : 0 0 0 @list_default_left;
        .tab{
            width: 250px;
            .selected{
                background: #010101;
                color: #f1f1f1;
            }
            &:first-child{
                ::before{
                    content:"";
                    position: absolute;
                    height: 4px;
                    width: ~"calc(100vw - @{list_default_left} * 2)";
                    background: #000;
                    top: 240px;
                    left: @list_default_left;
                    z-index: 1000;
                    pointer-events: none;
                }
            }
        }
    }
}
@__DEV__: true;