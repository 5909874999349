@import '../../../style/CommonStyle.module.less';

@IMAGE_SIZE: 114px;
@LAYER_WIDTH: 840px;
.myInfo {
    width: @LAYER_WIDTH;
    margin-left: 45px;
    .profileImage {
        width: @IMAGE_SIZE;
        height: @IMAGE_SIZE;
        vertical-align: middle;
        margin: 0 45px 0 42px;
        border-radius: 50%;
    }
    .infoLayer {
        width: ~'calc(@{LAYER_WIDTH} - @{IMAGE_SIZE} - 87px)';
        display: inline-block;
        vertical-align: middle;
        .name {
            font-family: Pretendard;
            font-size: 25px;
            font-weight: bold;
            letter-spacing: -0.75px;
            text-align: left;
            color: #010101;
        }
        .userInfo {
            margin-top: 25px;
            display: flex;
            > div {
                padding: 5px;
                height: 28px;
                line-height: 28px;
                font-size: 20px;
                color: #696969;
            }
            .title {
                position: relative;
                padding-left: 20px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 14px;
                    left: 0;
                    margin-right: 20px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #ff8f98;
                }
            }
            .detail {
                margin-right: 10px;
                font-size: 20px;
                font-weight: bold;
                color: #010101;
            }
        }
    }
}

@__DEV__: true;