.iconLayer{
	position: absolute;
	width: 130px;
	height: 130px;
	top: 281px;
	left: 50%;
	transform: translateX(-50%);
	background-image: url('../../../assets/icons/error_bg.png');
	.erroricon{
		width: 14px;
		height: 64px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-image: url('../../../assets/icons/error_icon.png');
	}
}
.textlayer{
	position: absolute;
	top: 548px;
	width: 100%;
	height: auto;
	text-align: center;
	font-family: Pretendard;
	font-weight: bold;
	.title{
		font-size: 40px;
		height: 48px;
    	line-height: 48px;
		letter-spacing: -1.2px;
		color: #010101;
	}
	.subtitle{
		margin-top: 17px;
		font-size: 30px;
		height: 36px;
		line-height: 36px;
		letter-spacing: -0.9px;
		color: #737373;
	}
}
.bottomBtnLayer {
	position: absolute;
	bottom: 239px;
	width: auto;
	height: auto;
	left: 50%;
	transform: translateX(-50%);
	>div{
		position: relative;
		margin-left: 10px;
		margin-right: 10px;
	}
}
@__DEV__: true;