.customimage{
    margin:0;
    &.hidden {
        opacity: 0;
    }
    &:not(.hidden){
        &.slow{
            transition: opacity 500ms ease-in-out;
        }
        &.normal{
            transition: opacity 250ms ease-in-out;
        }
        &.fast{
            transition: opacity 10ms ease-in-out;
        }
    }
}

@__DEV__: true;