@import '../../../../../../../style/CommonStyle.module.less';
.container{
    position: absolute;
    top: 240px;
    left: 53px;
    width: 90%;
    height: 68%;
    background: #f1f1f1;
    text-align: center;
    font-family: 'Pretendard';
    .title{
        padding: 100px 0 50px 0;
        font-size: 25px;
        letter-spacing: -0.75px;
        color: #1f1f1f;
        font-weight: bold;
    }
    .detail{
        font-size: 25px;
        letter-spacing: -0.75px;
        color: #909090;
    }
    .imgStyle{
        margin: 0 auto;
        width: 200px;
        height: 200px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: 50px 50px;
        animation: animatedImage 2s linear infinite alternate;
        background-image: url("../../../../../../../../assets/icons/candy_search.png");
    }
}

@keyframes animatedImage {
    0% {
        background-position: 30px center;
        opacity: 0.5;
    }
    50% {
        background-position: 90px center;
        opacity: 1;
    }
    100% {
        background-position: 30px center;
        opacity: 0.5;
    }
  }
@__DEV__: true;