@import '../../../style/CommonStyle.module.less';
@TITLE_LAYER_HEIGHT: 60px;
.calendar {
    width: 665px;
    height: 680px;
    .pickerlayer {
        width: 420px;
        margin: 0 auto;
        height: @TITLE_LAYER_HEIGHT;
    }
    .dateLayer {
        height: ~'calc(100% - (@{TITLE_LAYER_HEIGHT}))';
        .weekdays {
            height: 44px;
            display: flex;
            gap: 2px;
            margin-bottom: 6px;
            .weekdayItem {
                background-color: #fbfbfb;
                width: 93px;
                height: 46px;
                font-size: 20px;
                font-weight: bold;
                color: #010101;
                text-align: center;
                vertical-align: middle;
                line-height: 2rem !important;
            }
            .title {
                background-color: #fbfbfb;
                font-size: 20px;
                font-weight: bold;
                color: #010101 !important;
            }
            > div > div {
                height: 50px;
                line-height: 50px;
            }
        }
    }
    .datelist {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        gap: 2px;

        .calendarItem {
            position: relative;
            width: 92px;
            margin-right: 1px;
            height: 93px;

            // background-color: #fbfbfb;
            .title {
                font-size: 20px;
                font-weight: bold;
                font-family: 'Pretendard';
                letter-spacing: -0.6px;
                text-align: center;
                color: #010101;
                &.dark {
                    color: #c6c6c6;
                }
            }
            .historylayer {
                position: absolute;
                top: 66%;
                left: 50%;
                transform: translate(-50%, -50%);

                .dot {
                    width: 25px;
                    height: 5px;
                    display: inline-block;
                    margin-left: 2px;
                    margin-right: 2px;
                    border-radius: 10px;
                    &.largedot {
                        width: 106px;
                    }
                }
                .graph {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                }
            }
            &.selected {
                box-sizing: border-box;
                border-radius: 5px;
                border: 3px dashed @candy-spotlight-color;
                margin-top: 0px;
                .title {
                    margin-top: -3px;
                }
                .historylayer{
                    margin-top: 1px;
                }
            }
            &.defaultDate{
                box-sizing: border-box;
                border-radius: 5px;
                border: 3px solid @candy-spotlight-color;
                margin-top: 0px;
                .title {
                    margin-top: -3px;
                    color: #fff;
                }
                &::before{
                    content: "●";
                    position: absolute;
                    font-size: 40px;
                    top: -2px;
                    left: 23px;
                    color: #ff8f98;
                    z-index: -1;
                }
                .historylayer{
                    margin-top: 1px;
                }
            }
            &:global(.spottable):focus {
                color: #fff;
                border-radius: 5px;
                box-sizing: border-box;
                background-color: @candy-spotlight-color;
                .title{
                    color:white;
                }
            }
        }
    }
}

@__DEV__: true;