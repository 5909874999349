// .focus{
//     width: 332px;
//     height: 80px;
//     border-radius: 70px;
//     border: solid 10px rgba(0, 0, 0, 0.5);
// }
// .pressed{
//     width: 332px;
//     height: 80px;
//     border-radius: 70px;
//     border: solid 10px #ededed;
// }
// .selected{
//     background-image: none !important;
//     background-color: #ea6c81;
// }

.test{
    display: flex;
    margin-left: 100px; 
}
@__DEV__: true;