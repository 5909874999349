.popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white !important;
    color: black;
    width: 653px;
    height: 500px;
    box-shadow: none !important;
    margin-bottom: 280px !important;
    border-radius: 5px;
    .inputBox{
        margin-top: 45px !important;
        margin: 0 auto;
        padding: 0 60px;
    }
    .inputField{
        width: 440px;
        height: 84px;
        padding:0 !important;
        border-radius: 5px;
        border: 4px solid #b2b2b2;
        >div{
            width:100%;
            height:100%;
            background: #fff !important;
            filter:none !important;
            border-radius: 5px;
            margin: -4px;
            border: 4px solid #ff8f98;
        }
    }
    .title{
        font-family: "Pretendard";
        font-size: 40px;
        font-weight: bold;
        letter-spacing: -1.2px;
        text-align: center;
        padding: 65px 0 20px 0;
        font-weight: bold;
    }
    .bottomBtnLayer{
        text-align: center;
        margin-top: 30px;;
        padding: 20px 0 20px 0;
        .nomalBtn{
            &:first-child{
                margin-right: 20px;
            }
        }
    }
}
@__DEV__: true;