@import '../../style/CommonStyle.module.less';
.button{
    width: 26px;
    height: 26px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 10ms ease-in-out;
    z-index: 5;
}
&.selected{
    opacity: 1;
}
.topbutton{
    width: 37px;
    height: 37px;
    &:focus{
        transform: scale(1.4);
    }
}
.exit{
    background-image:url(../../../assets/icons/candy_exit_normal.png);
    &:global(.spottable):focus{
        background-image:url('../../../assets/icons/candy_exit_highlight.png');
    }
}
.search{
    background-image:url(../../../assets/icons/candy_search_normal.png);
    &:global(.spottable):focus{
        background-image:url(../../../assets/icons/candy_search_highlight.png);
    }
}
.settings{
    background-image:url(../../../assets/icons/candy_setting_normal.png);
    &:global(.spottable):focus{
        background-image:url(../../../assets/icons/candy_setting_highlight.png);
    }

}
.information{
    width: 62px;
    height: 62px;
    background-size: 8px 30px;
    background-image:url(../../../assets/icons/icon_information.png);
    border-radius: 31px;
    background-color: #d9d9d9;
    &:focus{
        background-color: @candy-spotlight-color;
    }
}
.favorite{
    width: 62px;
    height: 62px;
    background-size: 40px 40px;
    background-image:url(../../../assets/icons/favorite.png);
    border-radius: 31px;
    &:focus{
        background-size: 100% 100%;
        background-image:url(../../../assets/icons/favorite_focused.png);
    }
    &.selected{
        background-image:url(../../../assets/icons/favorite_selected.png);
    }
    &.selected:focus{
        background-image:url(../../../assets/icons/favorite_selected_focused.png);
    }
}
.like{
    width: 62px;
    height: 62px;
    background-size: 40px 40px;
    background-image:url(../../../assets/icons/like.png);
    border-radius: 31px;
    &:focus{
        background-size: 100% 100%;
        background-image:url(../../../assets/icons/like_focused.png);
    }
    &.selected{
        background-image:url(../../../assets/icons/like_selected.png);
    }
    &.selected:focus{
        background-image:url(../../../assets/icons/like_selected_focused.png);
    }
}
.dislike{
    width: 62px;
    height: 62px;
    background-size: 40px 40px;
    background-image:url(../../../assets/icons/dislike.png);
    border-radius: 31px;
    &:focus{
        background-size: 100% 100%;
        background-image:url(../../../assets/icons/dislike_focused.png);
    }
    &.selected{
        background-image:url(../../../assets/icons/dislike_selected.png);
    }
    &.selected:focus{
        background-image:url(../../../assets/icons/dislike_selected_focused.png);
    }
}


@__DEV__: true;