@panelWidth: 1004px;
@panelHeight: 817px;
.detailpopupPanel {
  background-color: #ffffff !important;
  width: @panelWidth !important;
  height: @panelHeight !important;

  >div>main {
    background-color: #ffffff !important;
    border-radius: 5px;
  }

  >div>main>article>section {
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-bottom: 0px !important;
    font-family: Pretendard;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.75px;
    text-align: left;
    color: #909090;
  }

  .panel {
    height: @panelHeight;
    .header {
      background-size: cover;
      width: 100%;
      height: 100%;
      padding: 50px 50px 0 43px;
      .btnWrap {
        display: flex;
        margin-left: 20px;
        >div{
          margin-right: 10px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
      >div {
        padding-bottom: 50px;
      }

      h1>div>div{
        color: #010101;
        font-family: Pretendard;
        font-size: 40px;
        font-weight: bold;
        // font-size: 45px;
      }
      h2{
        color: #454545;
        font-family: Pretendard;
        font-size: 30px;
        font-weight: 500;
      }
      .exitBtn {
        position: absolute;
        margin-left: 0px;
        right: 50px;
        top: 50px;
      }
    }
    .bgImage{
      position: fixed;
      width: 363px;
      height: 430px;
      top: 30px;
      right: 50px;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
    .description{
      font-size: 25px;
      font-weight: 500;
      height: 123px;
      margin: 45px 0;
      line-height: normal;
      letter-spacing: -0.75px;
      white-space: normal;
      display: flex;

      >div{
        padding-right: 45px !important;
      }
      .text{
        color: #909090;
      }
    }
    .list{
      height: 406px;
    }
  }
}
@__DEV__: true;