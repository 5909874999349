.panel{
	.header{
		width: 1800px;
		height: 190px;
		margin-left: auto;
		margin-right: auto;
		// border: 1px solid;
		.mainTilte {
			position: relative;
			font-size: 74px;
			width: 1800px;
			margin-left: auto;
			margin-right: auto;
			font-family: "LG SmartFont Bold";
			margin-top: 142px;
			text-align: center;
			// border: 1px solid;
		}
		.descriptionInfo{
			position: relative;
			font-size: 34px;
			font-family: "LG SmartFont Light";
			width: 1075px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 50px;
			text-align: center;
			white-space: pre-wrap;
			line-height: 1.18;
			// border: 1px solid;
		}
		.descriptionDate{
			position: relative;
			font-size: 18px;
			font-family: "LG SmartFont Light";
			width: 1600px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
			text-align: center;
			white-space: pre-wrap;
			// border: 1px solid;
		}
	}
	.textBoxTitle {
		position: relative;
		font-size: 28px;
		margin-left: 214px;
		margin-top: 30px;
		font-family: "LG SmartFont SemiBold";
	}
	.scrollerWrap{
		background-color:rgba(255, 255, 255, 0.16);
		position: absolute;
		width: 1570px;
		height: 348px;
		font-size: 24px;
		font-family: "LG SmartFont Light";
		word-break: break-all;
		border-radius: 22px;
		top: 412px;
		left: 171px;
		background-size : 1570px 348px;
	}
	.scroller{
		width: 1570px;
		height: 348px;
		padding-left: 30px;
		padding-top: 20px;
		padding-right: 50px;
		padding-bottom: 20px;
		white-space: pre-wrap;
		font-size: 24px;
		font-family: "LG SmartFont Light";
		>div:nth-child(2) > div:nth-child(1) {
			background: url("../../../assets/termsConditions/bar_scroll.png");
			width: 16px;
			height: 320px;
			left: -15px;
			top : -5px;
			opacity: 0.5;
			> div:nth-child(1) {
				width: 16px;
				background-size: cover;
				&:focus{
					// background-image: url("../../../assets/termsConditions/scroll_thumbnail.png");
					left: 0;
				}
			}
		}
		.termsTitle{
			font-size: 24px;
			font-family: "LG SmartFont Light";
			margin-top: 60px;
		}
		.termsTitle:first-child{
			margin-top: 0px;
		}
		.termsDetail{
			margin-top: 20px;
			margin-bottom: 20px;
			.termsDetailTitle{
				font-size: 24px;
				font-family: "LG SmartFont SemiBold";
			}
			.termsDetailInfo{
				min-height: 100px;
				margin-top: 10px;
				background-color:rgba(255, 255, 255, 0.16);
				padding-left: 25px;
				padding-right: 15px;
				padding-top: 15px;
				padding-bottom: 15px;
				border-radius: 22px;
				word-break: break-word;
				font-size: 18px;
				font-family: "LG SmartFont Light";
			}
		}
		.termsDetail:last-child{
			margin-bottom: 0px;
		}
	}
	.effective{
		position: absolute;
		display: inline-flex;
		top: 778px;
		left: 214px;
		.effectiveTitle{
			font-size: 28px;
			font-family: "LG SmartFont SemiBold";
		}
		.effectiveDate{
			font-size: 24px;
			margin-left: 40px;
			font-family: "LG SmartFont Light";
		}
	}
	.termsAgreeInfo{
		position: absolute;
		width: 1420px;
		top: 834px;
		left: 248px;
		font-size: 18px;
		font-family: "LG SmartFont Light";
		word-break: keep-all;
		// border: 1px solid;
	}
	.bottomBtnLayer {
		position: absolute;
		top: 928px;
		width: auto;
		height: auto;
		left: 50%;
		transform: translateX(-50%);
		.nomalBtn{
			position: relative;
			margin-left: 30px;
			margin-right: 30px;
		}
	}
}

@__DEV__: true;