// VideoPlayer.module.less
//
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/mixins.less";
@import "~@enact/sandstone/styles/skin.less";

.videoPlayer {
	// Set by counting the IconButtons inside the side components.
	--liftDistance: 0px;

	overflow: hidden;

	.video {
		height: 100%;
		width: 100%;
	}

	.preloadVideo {
		display: none;
	}

	.overlay {
		position: absolute;
		.position(0);

		&::after {
			content: "";
			position: absolute;
			.position(auto, 0, 0, 0);
			height: 80%;
			transform-origin: bottom;
			// Fancier gradient for future reference. Keeping linear-gradient as specified from Enyo.
			// background-image: radial-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%);
			// background-size: 170% 200%;
			// background-position: bottom center;
		}
	}
	@keyframes spin {
		0% {
		  transform: rotate(0.25turn);
		}
		33% {
		  transform: rotate(0.5turn);
		}
		80% {
		  transform: rotate(0.95turn);
		}
		85% {
		  transform: rotate(1turn);
		}
		100% {
		  transform: rotate(1.25turn);
		}
	  }
	.spinner {
		background-image: url("../../../assets/icon_loading.png");
		width: 100px;
		height: 100px;
		position: relative;
		background-size: cover;
		border-radius: 20.8125rem;
		overflow: hidden;
		margin: 490px auto;
		animation: none 1.25s linear infinite;
		animation-name: spin;
		// animation-play-state: paused;
	}
	.controlFeedbackBtnLayer{
		position: absolute;
		z-index: 50;
		top: 506px;
		left: 0;
		padding: 0;
		width: 100%;
		height: 94px;
		display: flex;
		justify-content: space-between;
		&.lift {
			transform: translateY(~"calc(var(--liftDistance) * -1)");
			transition: transform 0.3s linear;
		}
	}
	.fullscreen {
		.miniFeedback {
			position: absolute;
			z-index: 50;
			top: 506px;
			left: 0;
			padding: 0;
			width: 100%;
			height: 94px;
			-webkit-margin-end: 0px;
			pointer-events: none;
		}
		.exitBtnLayer{
			position: absolute;
			z-index: 50;
			top: 0px;
		}
		.bottom {
			position: absolute;
			z-index: 100;  // Value assigned as part of the VideoPlayer API so layers may be inserted in-between
			bottom: 26px;
			left: 0;
			right: 0;
			// left: @sand-video-player-padding-side;
			// right: @sand-video-player-padding-side;

			&.lift {
				transform: translateY(~"calc(var(--liftDistance) * -1)");
				transition: transform 0.3s linear;
			}

			&.hidden {
				pointer-events: none;

				.sliderContainer {
					position: absolute;
					left: 0;
					right: 0;
				}
			}

			.infoFrame {
				display: flex;
				margin-left: 64px;
			}

			.sliderContainer {
				// display: flex;
				position:relative;
				align-items: center;
				margin-left: 90px;
				margin-right: 90px;
				height: 70px;
				> *:first-child {
					text-align: right;
				}

				.enact-locale-rtl({
					direction: ltr;
				});
			}
			.candyMediaControls{
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.controlsHandleAbove {
		pointer-events: none;
		position: absolute;
		.position(0, 0, auto, 0);
	}

	// Skin colors
	.applySkins({
		.fullscreen {
			.bottom {
				background-color: @sand-video-player-bottom-bg-color;

				.infoFrame {
					text-shadow: @sand-video-player-title-text-shadow;
				}
			}
		}

		.overlay {
			z-index: 2;
			&.scrim::after {
				background: @sand-video-player-scrim-gradient-color
			}
		}
	});
}

@__DEV__: true;