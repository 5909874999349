@import '../../../../../../../style/CommonStyle.module.less';
.container{
    position: absolute;
    top: 230px;
    left: 50px;
    width: 91%;
    height: 71%;
    font-family: 'Pretendard';
    text-align:center;
    background: #f1f1f1;
    font-size: 25px;
    .title{
        padding: 50px;
        letter-spacing: -0.75px;
        color: #1f1f1f;
        >span{
            font-weight: bold;
        }
    }
    .candyName{
        position:absolute;
        left: 150px;
        color: #1f1f1f;
    }
    .connect{
        position:absolute;
        right: 100px;
        color: #737373;
    }
    .loading{
        position: relative;
        span{
            display: inline-block;
            width: 9px;
            height: 9px;
            background-color: gray;
            border-radius: 50%;
            animation: loading 1s 0s linear infinite;
            &:nth-child(1){
                position:absolute;
                top: 35px;
                right: 185px;
                animation-delay: 0s;
                background-color: #ff8f98;
            }
            &:nth-child(2){
                position:absolute;
                top: 35px;
                right: 170px;
                animation-delay: 0.3s;
                background-color: #ffb5bb;
            }
            &:nth-child(3){
                position:absolute;
                top: 35px;
                right: 155px;
                animation-delay: 0.6s;
                background-color: #f1f1f1;
            }
        }
    }
}

.popup{
    margin-bottom: 278px !important;
    >div>div>div>div>div{
        padding:25px 0 !important;
        margin: 0 !important;
    }

}
@keyframes loading {
    0%,
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
    50% {
      opacity: 1;
      transform: scale(1.2);
    }
  }
@__DEV__: true;