// Times.module.less
//
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/mixins.less";

.times {
	position: absolute;
	font-family: "LG SmartFont SemiBold";
	width: 100%;
	top: 19px;
	font-size: 30px;
	font-weight: bold;
  	letter-spacing: -1.4px;
	line-height: 30px;
	white-space: nowrap;

	> * {
		display: inline-block;
	}

	.separator {
		padding: 0 1ex;
	}

	.enact-locale-rtl({
		direction: ltr;
	});
}

@__DEV__: true;