@import '../../../../../../style/CommonStyle.module.less';
.container{
    margin: 40px 75px;
    text-align: center;
    background: #f1f1f1;
    font-family: 'Pretendard';
    .empty{
        font-size: 25px;
        color: #909090;
        margin: 100px 0 50px 0;
        letter-spacing: -0.75px;
        // font-weight: bold;
        >span{
            font-weight: bold;
        }
    }
    .detail{
        font-size: 25px;
        font-weight: 500;
        color: #909090;
    }
    .scan{
        font-size: 25px;
        margin: 65px 0 70px 0;
    }
}
@__DEV__: true;