@import '../../../../../../../style/CommonStyle.module.less';

.container{
    position: absolute;
    top: 240px;
    left: 53px;
    width: 90%;
    height: 68%;
    background: #f1f1f1;
    text-align: center;
    font-family: 'Pretendard';
    .title{
        padding: 100px 0 50px 0;
        font-size: 25px;
        letter-spacing: -0.75px;
        color: #1f1f1f;
        font-weight: bold;
    }
    .detail{
        font-size: 25px;
        letter-spacing: -0.75px;
        color: #909090;
    }

    .imgStyle{
        margin: 50px auto;
        width: 50px;
        height: 50px;
        background-size: 50px;
        background-repeat: no-repeat;
        background-image: url(../../../../../../../../assets/icons/candy_search.png);
    }
}
@__DEV__: true;