.arrow{
    width: 74px;
    height: 44px;
    background-size: contain;
    background-image:url(../../../assets/list/candy_scrollArrow_normal.png);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    &.bottom{
        bottom: 20px;
    }
    &.top{
        bottom: 0px;
        top: initial;
        transform: rotate(180deg) translateX(50%);
    }
    &.hover{
        background-image:url(../../../assets/list/candy_scrollArrow_focus.png);
    }
}
:global(.spottable):focus{
    .arrow{
        background-image:url(../../../assets/list/candy_scrollArrow_focus.png);
    }
}
@__DEV__: true;