.panel {
	.headContainer{
		z-index: 40;
		position: relative;
		display: inline-flex;
		width: 100%;
		.backBtn {
			position: relative;
			margin-left: 73px;
			margin-top: 43px;
		}
		.title{
			margin-left:40%;
			margin-top: 65px;
			font-size: 30px;
			font-family: 'Pretendard';
			font-weight: bold;
			color: #000000;
		}
	}
	.inputBoxContainer{
		position: relative;
		margin-left: 90px;
		padding: 20px 0px 0px 0px;
		width: 1740px;
		height: 100px;
		line-height: 48px;
		>div:nth-child(1)>div:nth-child(1) {
			background-color: transparent;
		}
		.inputBox {
			width: 96%;
			height: inherit;
			color: #909090;
			>div{
            	background: #fff !important;
            	filter:none !important;
            	border-radius: 0;
				margin-bottom: -4px;
            	border-bottom: 4px solid #010101;
			}
			> input {
				background-color: transparent;
				font-size: 30px;
				color: #4c5059;
				font-family: 'Pretendard';
			}
		}
		.searchIcon{
			position:absolute;
			top:40px;
			right:60px;
			width: 41px;
			height: 41px;
			color: black;
		}
	}

	.toggleContainer {
		position: relative;
		z-index: 50;
		display: flex;
		margin-left: 620px;
		> div{
			font-size: 23px;
		}
	}

	.moreInfo{
		width: 1920px;
		justify-content: center;
		display: flex;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}


	.mediaListTitleWrap{
		width: auto;
		position: relative;
	}

	.mediaListTitle {
		display: inline-block;
		position: relative;
		width: 108px;
		height: 36px;
		margin: 50px 244px 0px 90px;
		font-family: 'Pretendard';
		font-size: 30px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.9px;
		text-align: left;
		color: #010101;
	}

	.noneResult {
		position: relative;
		width: 100%;
		margin-top: 260px;
		text-align: center;
		font-size: 30px;
		line-height: 1.29;
		font-family: 'Pretendard';
		font-weight: bold;
		color: #010101;
	}

	.goallistlayer{
		margin-left: 30px;
		margin-right: 30px;
	}
	.medialistlayer{
		position: relative;
		height: 672px;
		margin-top: 20px;
	}


	.goalContentContainer{
		> div:nth-child(1) > div:nth-child(2) {
			margin-top: -10px;
		}
		> div:nth-child(2) {
			margin-top: -45px;
		}
		> div:nth-child(2) > div:nth-child(2){
			margin-top: -10px;
		}
	}

	.topBottomScrollArea{
		position:absolute;
		width: 100%;
		height: 100px;
		z-index: 21;
		&.touchGuide{
			opacity: 0.3;
			background-color: darkorange;
		}
	}

	.topScrollArea{
		transition: all 300ms ease;
		top: 310px;
		height: 73px;
	}

	.bottomScrollArea{
		bottom:0px;
		height: 91px;
    	background-image: linear-gradient(to top, #fafafa, rgba(250, 250, 250, 0));
	}
	.dummy{
		height: 300px;
		width:100%;
	}
	.searchContainer{
		position:relative;
		width: 1920px;
		height: 40px !important;
		margin-top: 40px;
		margin-left: 90px;
		display: flex;
		flex-wrap: wrap;
		z-index: 0;
		.title{
			margin: 10px 27px 11px 0;
			font-family: "Pretendard";
			font-size: 25px;
			font-weight: 500;
			line-height: 0.76;
			letter-spacing: -0.75px;
			text-align: left;
			color: #ff8f98;
		}
		.list{
			height:inherit;
		}
	}
}

@__DEV__: true;