.videoMain {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	right: 0;
	background-color: black;
	video {
		width: 100%;
		height: 100%;
		background-color: transparent;
		object-fit: contain;
		position: absolute;
	}
}
.playLogBg{
	width: 100%;
	height: 100%;
	position: absolute;
	margin: 0;
	transition: opacity 500ms ease-in-out;
	&.hide {
		opacity: 0;
	}
}
.hide {
	visibility: hidden;
}
.localGuide{
	position: absolute;
	top: 50px;
	color: red;
	left: 50%;
	transform: translateX(-50%);
}
.cameraSettingsButtonContainer {
	position: absolute;
    bottom: 140px;
    right: 50px;
	z-index: 50;
}
.exitBtnPosition{
	position: fixed;
	left: 80px;
	top: 80px;
	&.lt{
		left: initial;
		right: 80px;
	}
}
.autoSwitch{
    display: flex;
    align-items: center;
	>div:first-child{
		font-family: Pretendard;
		font-size: 32px;
		font-weight: 500;
		letter-spacing: -0.96px;
		color: rgba(255, 255, 255, 0.7);
	}
	>div{
		margin: 0;
		padding: 0;
	}
}

.bleNotiPopup{
    width: 652px !important;
	height: 450px !important;
    margin-bottom: 300px !important;
	>div>div>div>div>div:nth-child(2){
		padding: 30px 0 50px 0 !important;
	}
    .title{
        font-size: 40px !important;
        font-weight: bold;
        letter-spacing: -0.8px;
        margin-bottom: 30px;
    }
    .detailLimited{
        width: 382px !important;
	height: 67px !important;
        margin:-20px auto!important;
        font-size: 25px !important;
	padding-top:20px !important;
        letter-spacing: -0.75px !important;
        background-color: white !important;
        color:#909090 !important;
        line-height: 35px !important;
    }
}
@__DEV__: true;