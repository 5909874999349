@import '../../../style/CommonStyle.module.less';

@keyframes topGroupExtend {
    from {
        margin-left: 0;
      }
     to {
        margin-left: 50%;
     }
}
@keyframes topGroupReduce {
    from {
        margin-left: 50%;
      }
     to {
        margin-left: 0;
     }
}
.top-title-layer{
    height: @toplayerHeight;
    position: absolute;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    &.center {
        background-image: url(../../../../assets/banner/topLayer_bg_center.png);
    }
    &.left{
        background-image: url(../../../../assets/banner/topLayer_bg_left.png);
    }
    &.right{
        background-image: url(../../../../assets/banner/topLayer_bg_right.png);
    }
}
.topgroup{
    display: inline-block;
    white-space: nowrap;
    width: auto;
    position: absolute;
    transform: translateX(-50%);
    animation-duration: 0.5s;
    -moz-animation-name: topGroupReduce;
    -webkit-animation-name: topGroupReduce;
    animation-name: topGroupReduce;
    :global(.enyo-locale-right-to-left) & {
        transform: translateX(50%);
        margin-right: 50%;
    }
    &.extended{
        margin-left: 50%;
        -moz-animation-name: topGroupExtend;
        -webkit-animation-name: topGroupExtend;
        animation-name: topGroupExtend;
    }
}
.globalbutton {
    float: right;
    margin-top: 46px;
    margin-right: 40px;
    .account {
        display: inline-block;
        margin-right: 12px;
    }
    :global(.enyo-locale-right-to-left) & {
        float: left;
        margin-right: 0px;
        margin-left: 40px
    }
    >div {
        vertical-align: middle;
        display:inline-block;
        margin-left: 23px;
        margin-right: 23px;
    }
}
.logo{
    height: 37px;
    width: 116px;
    margin-left: 64px;
    margin-top: 43px;
    background-size: contain;
    // todo
    background-image: url(../../../../assets/candy_logo.svg);
    transition: opacity 500ms ease-in-out;
    background-repeat: no-repeat;
}
.hide {
    opacity: 0;
}
.isQAServer{
    position:absolute;
    color: red;
    top: 46px;
    font-family: Pretendard;
    font-size: 26px;
    left: 200px;
}
@__DEV__: true;