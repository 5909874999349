@import '../../../../../style/CommonStyle.module.less';

.contentContainer{
    position: absolute;
    top: 0;
    left: 520px;
    font-family: "Pretendard";
    background-color:#f1f1f1;
    width: 1220px;
    height: 830px;
    color: #010101;
    .header{
        font-size: 40px;
        margin: 75px 0 0 50px;
        color: #010101;
        font-weight: bold;
    }
    .monthContainer{
        position: absolute;
        top: 180px;
        left: 50px;
        .month{
            font-size: 28px;
            margin: 0 0 50px 0;
            >span{
                color: #ff8f98;
                font-weight: bold;
            }
        }
        .nextDate{
            font-size: 24px;
            font-weight: bold;
            margin: 20px 0;
            &::before{
                content:"";
                position: absolute;
                top: 130px;
                width: 350px;
                height: 4px;
                background-color: black;
            }
        }
        .title{
            font-size: 26px;
            font-weight: bold;
            margin: 80px 0 20px 0;
        }
        .detail{
            font-size: 22px;
            color: grey;
        }
    }
}
@__DEV__: true;