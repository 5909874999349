@import '../../../style/CommonStyle.module.less';
.buttonContainer {
    display: inline-block;
    position: relative;
    width: 120px;
    height: auto;
    margin-left: 28px;
    margin-right: 28px;
    z-index: 1;
    .name {
        width: 100%;
        height: 30px;
        margin-top: 20px;
        text-align: center;
        white-space: nowrap;
        font-family: Pretendard;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #b2b2b2;
    }
    .button{
        width:43px;
        height: 43px;
        margin-left: 38.5px;
        margin-top: 14px;
        background-size: cover;
    }
    &.selected {
        .name {
            margin-top: 18px;
            font-size: 25px;
            color:@candy-spotlight-color;
        }
        .button {
            margin-top: 43px;
            width: 43px;
            height: 43px;
        }
        .focusBg{
            position: absolute;
            width:107px;
            height:107px;
            top: 60px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            background-image:url(../../../../assets/top/topGroup_normal.png);
            background-size: cover;
        }
    }
    &.focused .name{
        color:@candy-spotlight-color;
    }
    &.selected.focused .focusBg{
        position: absolute;
        width:107px;
        height:107px;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        background-image:url(../../../../assets/top/topGroup_focusbg.png);
    }
}
.category{
    .button{
        background-image:url(../../../../assets/top/candy_category_gray.png);
    }
    &.selected .button{
        background-image:url(../../../../assets/top/candy_category_pink.png);
    }
    &.focused .button{
        background-image:url(../../../../assets/top/candy_category_pink.png);
    }
    &.selected.focused .button{
        background-image:url(../../../../assets/top/candy_category_white.png);
    }
}
.home{
    .button{
        background-image:url(../../../../assets/top/candy_workout_gray.png);
    }
    &.selected .button{
        background-image:url(../../../../assets/top/candy_workout_pink.png);
    }
    &.focused .button{
        background-image:url(../../../../assets/top/candy_workout_pink.png);
    }
    &.selected.focused .button{
        background-image:url(../../../../assets/top/candy_workout_white.png);
    }
}
.dashboard{
    .button{
        background-image:url(../../../../assets/top/candy_dashboard_gray.png);
    }
    &.selected .button{
        background-image:url(../../../../assets/top/candy_dashboard_pink.png);
    }
    &.focused .button{
        background-image:url(../../../../assets/top/candy_dashboard_pink.png);
    }
    &.selected.focused .button{
        background-image:url(../../../../assets/top/candy_dashboard_white.png);
    }
}

@__DEV__: true;