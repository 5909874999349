@import '../../style/CommonStyle.module.less';
@WIDTH: 212px;
.tDrapdown{
    margin: 0px;
    // border-radius: 17px;
    // height: 57px;
    > div > div:first-child {
        width: @WIDTH !important;
        height: 70px;
        padding: 0px;
        margin: 0px;
        border: solid 2px @candy-spotlight-color;
        border-radius: 5px;
        font-family: "Pretendard";
        font-size: 25px;
        font-weight: 600;
        background-color: #ffffff;
        color: @candy-spotlight-color !important;
        > div:first-child {
            background-color: transparent !important;
        }
        > div:nth-child(2) {
            margin-left: 27px;
            >div{
                margin-right: 20px !important; //icon
                margin-left: 0px !important;
            }
        }
        &:focus{
            color: #ffffff !important;
            background-color: @candy-spotlight-color !important;
            border-color: white;
        }
    }
}
[id=floatLayer]{ //dropbox
    >div>[id]{
        >div:nth-child(2)>div:nth-child(1){
            border-radius: 5px;
            font-family: "Pretendard";
            font-size: 25px;
            font-weight: 600;
            background-color: #ffffff;
            color: @candy-spotlight-color !important;
            padding: 0;
            >div{
                width: @WIDTH;
                margin-top: 20px;
                height: auto !important;
                max-height: 1080px;
            }
        }
        >div:nth-child(2)>div:nth-child(1)>div>div>div>div>div:nth-child(1){ //font
            color: @candy-spotlight-color !important;
            font-size: 25px !important;
            margin: 0px !important;
            -webkit-margin-start: 0px !important;
            margin-inline-start: 0px !important;
            > div:first-child {
                background-color: transparent !important;
            }
            &:focus{
                color: #ffffff !important;
                background-color: @candy-spotlight-color !important;
            }
        }
    }
}
@__DEV__: true;