@import '../../style/CommonStyle.module.less';

.homebanner{ //default big
    position: relative;
    /*big*/
    height: @bannerMedium;
    .infoArea {
        width: auto;
        display: inline;
        height: 100%;
        margin-left: 162px;
        margin-right: 162px;
        position: relative;
        font-family: 'Pretendard';
        color: #000000;
    }
    .title{
        position: relative;
        width: 100%;
        height: 69px;
        line-height: 69px;
        font-size: 55px;
        font-weight: bold;
        letter-spacing: -1.8px;
        vertical-align: middle;
        font-weight: bold;
    }
    .subtitle{
        position: relative;
        display: block;
        width: 100%;
        height: 69px;
        margin-top: 60px;
        line-height: 69px;
        font-size: 45px;
        word-break:keep-all;
        overflow: hidden;
        text-align: justify;
        text-justify: inter-word;
        // will-change: transform;
        white-space: pre-wrap;
        font-weight: 600;
    }
    .buttonArea{
        position: absolute;
        top: 467px - @toplayerHeight;
        left: 50%;
        transform: translateX(-50%);
    }
    .buttonArea_2{
        position: absolute;
        top: 250px;
        left: 500px;
    }
    .preview{
        position: fixed;
        top:  0;
        width: 100%;
        height: @bannerMedium + @toplayerHeight;
        margin: 0px;
        background-size: cover;
        background-position: top;
        right: 0px;
        z-index: -1;
    }
    .localGuide{
        color: red;
        z-index: 0;
        text-align: center;
        padding-top: 5%;
    }
    &.small {
        height: @bannerSmall;
        .infoArea {
            .subtitle{
                margin-top: 40px;
            }
        }
        .title{
            height: 72px;
            line-height: 72px;
        }
        .preview{
            height: @bannerSmall + @toplayerHeight;
        }
        .moreInfo{
            /* hide subtitle */
            // width: 780px;
            // height: 82px;
            // margin-top: 12px;

            width: 1580px;
            top: 110px;
            height: 82px;
            margin-top: 5px;
            position: absolute;
            left: 0px;
        }
    }
}

.hide {
    opacity: 0;
}
.paginationArea{
    display: inline-block;
    white-space: nowrap;
    width: auto;
    position: absolute;
    bottom: 30px;
    transform: translateX(-50%);
    margin-top: 17px;
    left: 0px;
    margin-left: 90%;
    :global(.enyo-locale-right-to-left) & {
        margin-left: initial;
        transform: translateX(50%);
        margin-right: 90%;
        right: 0px;
    }
}


@__DEV__: true;